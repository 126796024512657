import Vue from 'vue';
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta'
import App from './App.vue';
import router from './router';
import store from './store';
import { domain, clientId, audience } from '../auth_config.json';
import { Auth0Plugin } from './auth';
import { StripePlugin } from '@vue-stripe/vue-stripe';
import $ from 'jquery';
import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip = false;
//Vue.prototype.$domainPath = 'http://127.0.0.1:5000';
Vue.prototype.$domainPath = 'https://cp-product.herokuapp.com';

const options = {
  pk: process.env.VUE_APP_STRIPE_KEY,
};

Vue.use(VueMeta)
Vue.use(VueApexCharts)
Vue.use(StripePlugin, options);

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
});

Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
