<template>
  <v-card class="text-center" id="complete" v-if="this.$parent.$parent.$parent.currentStep == 4" >
    <v-card-text >
      <v-container style="width: 90%; max-width: 600px; text-align: left;">
      <h2 class="mt-4">Thank you for your purchase.</h2>
      <p class="py-5">
        Your tokens have been secured and can be accessed at any time.
        Shortly, you'll receive an email with your confirmation receipt.
      </p>
      
      <v-row class="py-0">
          <v-col
            cols="12"
          >  
          <hr>
        </v-col>
          <v-col
            cols="12"
            sm="6"
            class="py-0"
          >  
          <p class="text-center overline descTitle">AMOUNT PAID</p>
          <p class="text-center descDetails">{{this.amount}}</p>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            class="py-0"
          >  
          <p class="text-center overline descTitle">DATE PAID</p>
          <p class="text-center descDetails">{{this.formatDate(this.date)}}</p>
        </v-col>
          <v-col
            cols="12"
            sm="6"
            class="py-0"
          >  
          <p class="text-center overline descTitle">TOKENS</p>
          <p class="text-center descDetails">{{this.numberWithCommas(this.tokens)}}</p>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            class="py-0"
          >  
          <p class="text-center overline descTitle">PAYMENT METHOD</p>
          <p class="text-center descDetails">{{this.method}}</p>
        </v-col>
      </v-row>
      
      <v-row class="mt-0" >
          <v-col
            cols="12"
          >  
          <hr>
        </v-col>
        <v-col
          cols="12"
          v-if="newUser == 'false'"
        >  
          <h3 class="text-center mb-6" v-if="!embedded">You can login to your dashboard at any time to view your tokens.</h3>
          <v-btn
            :color="primaryColor"
            dark
            block
            elevation="0"
            x-large
            href="https://www.curepayapp.com/dashboard"
            target="_blank"
            style="color:white !important;"
          >Proceed To Dashboard</v-btn>

        </v-col>
        <v-col
          cols="12"
          v-else
        >  
          <h3 class="text-center mb-6" v-if="!embedded">In order to access your tokens, please create a password by clicking the link in your welcome email. </h3>
        </v-col>
      </v-row>

      <v-row class="mt-10">
          <v-col
            cols="12"
          >  
          <p class="text-center mb-0">If you have any questions, contact us at <a v-bind:style="{ color: primaryColor}" href="mailto:support@curepayapp.com">support@curepayapp.com</a></p>
        </v-col>
      </v-row>
    </v-container>
    </v-card-text>

  </v-card>
</template>

<script>


export default {
  props:[
    'embedded',
  ],
  components: {
    
  },
  data () {
    //console.log("COMPLETE : data");
    
    this.amount = this.$parent.$parent.$parent.reciept.amount;
    this.date = this.$parent.$parent.$parent.reciept.date;
    this.method = this.$parent.$parent.$parent.reciept.method;
    this.desc = this.$parent.$parent.$parent.reciept.desc;
    this.tokens = this.$parent.$parent.$parent.reciept.tokens;
    this.newUser = this.$parent.$parent.$parent.reciept.newUser;

    return {
      token: null,
      primaryColor: '',
    };
  },
  created() {
    this.primaryColor = this.$parent.$parent.$parent.primaryColor + " !important";
  },
  methods: {
    test () {
      //console.log("test");
    },
    formatDate(dateString) {
      let dateFormat = new Date(dateString).toUTCString();
      dateFormat = dateFormat.split(' ').slice(1, 4).join(' ');
      return dateFormat;
      //return dateString;
    },
    numberWithCommas(string) {
      if(string == null)  return;
      let partArray = string.split(".");
      if(partArray.length > 1) {
        let leftPart = partArray[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let rightPart = partArray[1]
        rightPart = rightPart.substring(0,3);
        return leftPart + "." + rightPart;
      }else{
        return string;
      }      
    },
  }
};
</script>

<style>
  hr {
    border: 1px dotted #ccc;
    border-style: none none dotted; 
    color: #fff; 
    background-color: #fff;
    margin: 10px 0px;
  }
  #complete {
    background-color: transparent;
  }

  #complete h1{
    font-size: 3em;
    line-height: 1em;
  }

  #complete .descTitle{
    margin-bottom: 0px;
    line-height: 1.5em;
  }

  #complete .descDetails{
    text-transform: capitalize;
  }


</style>
