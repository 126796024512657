<template>
  <div id="checkout" v-if="this.$parent.$parent.$parent.currentStep == 2">
    <v-sheet
      color="grey darken-2"
      elevation="1"
      height="110"
      width="100%"
      align="center"
      justify="center"
      id="banner"
    >
      <v-container fill-height fluid>
        <v-row align="center"
            justify="center">
          <v-col>
            <h3 class="grey--text text--lighten-1 font-weight-regular caption mt-3">{{this.$parent.$parent.$parent.tokenName}} TOKENS</h3>
            <h1 class="white--text line-height">${{this.$parent.$parent.$parent.purchaseAmount}}.00</h1>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-form
      ref="form"
      @submit.prevent="submit"
      v-model="isFormValid"
      class="ma-4"
    >
      <v-container fill-height class="mb-0">

        <v-row class="mt-0" v-if="!embedded">
          <v-col
            cols="6"
            sm="6"
            class="pa-2"
          >  
            <v-text-field
              label="First Name"
              outlined
              hide-details="true"
              required
              :rules="nameRules"
              v-model="firstName"
              :disabled="embedded"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            class="pa-2"
          >
            <v-text-field
              label="Last Name"
              outlined
              hide-details="true"
              required
              :rules="nameRules"
              v-model="lastName"
              :disabled="embedded"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="pa-2"
          >
            <v-text-field
              label="Email"
              outlined
              hide-details="true"
              required
              :rules="emailRules"
              class="mt-0"
              v-model="email"
              :disabled="embedded"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            class="pa-2 mt-2"
          >
            
            <div id="card-block" class="mt-0">
              <div id="card-error"></div>
              <div id="card-number" class="field-cc"></div>
              <div id="card-expiry" class="field-cc"></div>
              <div id="card-cvc" class="field-cc"></div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="pa-2"
          >
            <v-text-field
              label="ZIP / Postal Code"
              outlined
              hide-details="true"
              v-model="zipcode"
              required
              :rules="zipRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"
          class="pa-2"
          >
            
            <v-btn 
            :color="primaryColor"
            dark
            block
            x-large
            elevation="0"
            id="checkoutBTN" 
            :disabled="!isFormValid"
            @click="createToken">Pay</v-btn>

          </v-col>  
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
//import { StripeElementCard } from '@vue-stripe/vue-stripe';
export default {
  props:[
    'embedded',
  ],
  components: {
    //StripeElementCard,
  },
  data () {
    //console.log("CHECKOUT : data");
    this.publishableKey = process.env.VUE_APP_STRIPE_KEY;
    this.elementStyle = {
      base: {
        iconColor: '#ff0000',
        color: '#ff0000',
        fontWeight: '500',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#ff0000',
        },
        '::placeholder': {
          color: '#ff000',
        },
      },
      invalid: {
        iconColor: '#ff5252',
        color: '#ff5252',
      },
    };

    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.zipcode = "";
    this.customer = {};

    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      isFormValid: false,
      primaryColor: '',

      emailRules: [ 
        v => !!v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'REQUIRED',
      ],
      nameRules: [
        v => !!v || 'REQUIRED',
      ],
      zipRules: [
        v => !!v || 'REQUIRED',
      ]
    };
  },
  computed: {
    stripeElements () {
      return this.$stripe.elements();
    },
  },
  mounted () {
    const style = {
      base: {
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#ff0000',
        iconColor: '#ff0000',
      },
    };
    this.cardNumber = this.stripeElements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');
    
    if(this.$parent.$parent.$parent.customer.hasOwnProperty('firstName')) this.firstName = this.$parent.$parent.$parent.customer['firstName'];
    if(this.$parent.$parent.$parent.customer.hasOwnProperty('lastName')) this.lastName = this.$parent.$parent.$parent.customer['lastName'];
    if(this.$parent.$parent.$parent.customer.hasOwnProperty('email')) this.email = this.$parent.$parent.$parent.customer['email'];
    if(this.$parent.$parent.$parent.customer.hasOwnProperty('zipcode')) this.zipcode = this.$parent.$parent.$parent.customer['zipcode'];
  },
  created() {
    this.primaryColor = this.$parent.$parent.$parent.primaryColor;
  },
  updated() {
    
  },
  beforeDestroy () {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    submit () {
      this.$refs.elementRef.submit();
    },
    async createToken () {
      this.$refs.form.validate();
      this.customer['firstName'] = this.firstName;
      this.customer['lastName'] = this.lastName;
      this.customer['email'] = this.email;
      this.customer['zipcode'] = this.zipcode;
      this.$parent.$parent.$parent.customer = this.customer;

      var cardData = { 
        address_zip: this.zipcode
      }

      const { token, error } = await this.$stripe.createToken(this.cardNumber, cardData);
      if (error) {
        document.getElementById('card-error').innerHTML = error.message;
        return;
      }
      
      this.$emit('process', {'token': token.id, 'customer':this.customer});
    },
    filter: function(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>

<style >

#banner{
  background-color: #393939 !important;
  background-image: url("https://www.transparenttextures.com/patterns/black-twill.png");
  background-repeat: repeat;
}

#checkout .v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--full-width>.v-input__control>.v-input__slot, .v-text-field--outlined>.v-input__control>.v-input__slot {
    /*min-height: 50px;*/
}

.line-height{
  line-height: .9em;
}



#checkout input.InputElement.is-complete.Input{
  background-color: #32315E !important;

} 
#checkout label.v-label.theme--light {
  color: #aab7c4 !important;

}

#checkout .v-input{
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 5%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 1px 5px 0 rgb(0 0 0 / 5%);

  }

#checkout .v-input__slot{
  margin: 0px;
}

#checkout .v-text-field__details {
    position: absolute;
    margin: -18px -5px;
    right: 0;
}

#checkout #card-error{
  position: relative;
  margin: -20px -5px;
  left: 0;
  float: left;
  width: 100%;
  display: block;
  text-align: right;
  font-size: 12px;
  text-transform: uppercase;
  color: #ff5252;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
    color: rgba(0,0,0,.2);
}

.field {
  background: white;
  box-sizing: border-box;
  font-weight: 400;
  border: 1px solid #ff0000;
  border-radius: 5px;
  color: #32315E;
  outline: none;
  flex: 1;
  line-height: 48px;
  padding: 15px 10px;
  cursor: text;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 5%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 1px 5px 0 rgb(0 0 0 / 5%);
  border: 1px solid rgba(0,0,0,.2);
}

.field-cc {
  background: transparent;
  box-sizing: border-box;
  font-weight: 400;
  border-radius: 0px;
  color: #32315E;
  outline: none;
  flex: 1;
  line-height: 48px;
  padding: 15px 10px;
  cursor: text;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 5%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 1px 5px 0 rgb(0 0 0 / 5%);
  border: 1px solid green;
}


#card-block{
  display: inline-block;
  width:  100%;
  border-radius: 5px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 5%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 1px 5px 0 rgb(0 0 0 / 5%);
  /*border: 1px solid rgba(0,0,0,.2);*/
}

#card-number{
  width: 100%;
  box-shadow: none;
  display: block;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid rgba(0,0,0,0);
}
#card-expiry{
  width: 50%;
  display: block;
  float: left;
  box-shadow: none;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0px 0px 0px 5px;
  border-right: 1px solid rgba(0,0,0,0);
  margin-top: -2px;
}
#card-cvc{
  width: 50%;
  display: block;
  float: right;
  box-shadow: none;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0px 0px 5px 0px;
  margin-top: -2px;
}

#custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}

.StripeElement--focus{
  border-right: 1px solid red;
  border-top: 1px solid red;
}

.StripeElement--invalid{
  border: 2px solid red !important;
  border-style: inset;
  color:  red !important;
  /*box-shadow: inset 0 0 2px #0f0, inset 0 0 2px #0f0, inset 0 0 2px #0f0 !important;*/
}

.StripeElement--invalid inputElement{
  color:  red;
}

#checkout .v-sheet h1{
  font-size: 3em;
}

#checkout .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #ccc !important;
  color:  white !important;
}

</style>