var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {}, [_c('v-parallax', {
    attrs: {
      "fill-height": "",
      "dark": "",
      "src": "/img/bg.jpg",
      "height": "600"
    }
  }, [_c('v-container', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-btn', {
    staticClass: "white--text mt-2 float-end",
    attrs: {
      "elevation": "0",
      "width": "175",
      "color": "transparent",
      "align": "right",
      "href": "/dashboard"
    }
  }, [_vm._v("MY DASHBOARD")])], 1), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-slide-y-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }]
  }, [_c('v-container', [_c('div', {}, [_c('svg', {
    staticClass: "svgLogo",
    attrs: {
      "focusable": "false",
      "viewBox": "0 0 78 17",
      "width": "250",
      "height": "60",
      "role": "img",
      "aria-labelledby": "stripe-title"
    }
  }, [_c('title', {
    attrs: {
      "id": "stripe-title"
    }
  }, [_vm._v("CURE Pay")]), _c('g', {
    attrs: {
      "fill-rule": "evenodd",
      "color": "#ff9800"
    }
  }, [_c('path', {
    staticClass: "cls-1",
    attrs: {
      "fill": "currentcolor",
      "d": "M74.83,0H47.72A3.33,3.33,0,0,0,44.4,3.32V14.53a3.33,3.33,0,0,0,3.32,3.31H74.83a3.32,3.32,0,0,0,3.31-3.31V3.32A3.32,3.32,0,0,0,74.83,0Z"
    }
  }), _c('path', {
    staticClass: "cls-2",
    attrs: {
      "fill": "white",
      "d": "M5.26,3.9c1.92,0,4.23.67,4.23,2.44V7.87H6.75V7.29c0-.46-.62-.71-1.36-.71A2,2,0,0,0,3.24,8.84a2.25,2.25,0,0,0,2.43,2.44,4.89,4.89,0,0,0,2.88-1.06l1.09,2.25a6.43,6.43,0,0,1-4.18,1.47C2.61,13.94,0,12.08,0,9A5,5,0,0,1,5.26,3.9Z"
    }
  }), _c('path', {
    staticClass: "cls-2",
    attrs: {
      "fill": "white",
      "d": "M11.79,6.45h-1.3V4.12H15V9.84c0,.9.35,1.27,1.06,1.27,1.27,0,2-1.47,2-2.92V6.45H16.77V4.12H21.3V11a.37.37,0,0,0,.41.41h.8v2.33h-3c-.88,0-1.33-.43-1.33-1.08v-.26a2.5,2.5,0,0,1,0-.28h0a3.5,3.5,0,0,1-3.27,1.84c-1.69,0-3.15-.89-3.15-3.59Z"
    }
  }), _c('path', {
    staticClass: "cls-2",
    attrs: {
      "fill": "white",
      "d": "M23.76,11.39h1.3V6.86a.37.37,0,0,0-.41-.41h-.89V4.12h3c.88,0,1.36.36,1.36,1.23v.51a3.4,3.4,0,0,1,0,.48h0A3.35,3.35,0,0,1,31.21,4a1.94,1.94,0,0,1,.37,0V7.16a3.18,3.18,0,0,0-.65-.06c-1.79,0-2.65,1.14-2.65,2.89v1.4h1.31v2.33H23.76Z"
    }
  }), _c('path', {
    staticClass: "cls-2",
    attrs: {
      "fill": "white",
      "d": "M37.36,3.9c2.87,0,4.47,2,4.47,4.64,0,.32-.07,1-.07,1h-6.1a2.14,2.14,0,0,0,2.24,1.77,5.33,5.33,0,0,0,2.7-.91l1.18,2.23a6.7,6.7,0,0,1-4.14,1.34,5,5,0,0,1-5.28-5A4.78,4.78,0,0,1,37.36,3.9ZM38.5,7.74a1.32,1.32,0,0,0-1.22-1.42,1.56,1.56,0,0,0-1.54,1.42Z"
    }
  }), _c('path', {
    staticClass: "cls-2",
    attrs: {
      "fill": "white",
      "d": "M48.18,13.72V12h1.07V5.87H48.18V4.12H53a4.52,4.52,0,0,1,1.61.22,3.09,3.09,0,0,1,1.85,3,3.13,3.13,0,0,1-1.89,3.06,4.72,4.72,0,0,1-1.51.2H51.66V12h1.07v1.75"
    }
  }), _c('polyline', {
    staticClass: "cls-2",
    attrs: {
      "fill": "white",
      "points": "56.26 13.72 56.26 11.97 57.05 11.97 59.67 4.12 62.4 4.12 65.02 11.97 65.81 11.97 65.81 13.72 61.8 13.72 61.8 11.97 62.63 11.97 62.27 10.7 59.8 10.7 59.44 11.97 60.27 11.97 60.27 13.72"
    }
  }), _c('path', {
    staticClass: "cls-2",
    attrs: {
      "fill": "white",
      "d": "M67.47,13.72V12h1.08V9.79L66.2,5.87h-.82V4.12h4V5.87h-.8l1,1.77a4,4,0,0,1,.21.47h0A4,4,0,0,1,70,7.64l1-1.77h-.8V4.12h4V5.87H73.3L71,9.79V12H72v1.75"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "fill": "currentcolor",
      "d": "M61.06,6.1H61a5.87,5.87,0,0,1-.19,1l-.51,1.77h1.44l-.5-1.77A5.87,5.87,0,0,1,61.06,6.1Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "fill": "currentcolor",
      "d": "M53.56,6.22A1.44,1.44,0,0,0,52.72,6H51.66V8.74h1a1.61,1.61,0,0,0,.84-.2A1.34,1.34,0,0,0,54,7.36,1.33,1.33,0,0,0,53.56,6.22Z"
    }
  })])])]), _c('h1', {
    staticClass: "text-h2 font-weight-thin mt-12",
    attrs: {
      "id": "buying"
    }
  }, [_vm._v(" Buying crypto "), _c('div', {
    attrs: {
      "id": "overlay"
    }
  }, [_vm._v("is "), _c('div', {
    attrs: {
      "id": "strike"
    }
  }, [_vm._v("/")]), _c('div', {
    attrs: {
      "id": "was"
    }
  }, [_vm._v("was")])]), _vm._v(" difficult. ")]), _c('h2', {
    staticClass: "my-4 font-weight-light"
  }, [_vm._v(" That’s why we’ve made it easy. Use traditional payment methods and secure your piece of any crypto project. ")])])], 1)])], 1)], 1)], 1), _c('v-main', {
    staticClass: "my-12"
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "sticky"
  }, [_c('Widget', {
    staticClass: "mb-12",
    staticStyle: {
      "max-width": "500px"
    },
    attrs: {
      "clientID": "6eae00be-b74e-4841-99fb-13a0d70ed737"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "5",
      "order-sm": "first",
      "order-md": "first"
    }
  }, [_c('v-sheet', {
    staticClass: "ma-4",
    attrs: {
      "min-height": "50vh",
      "rounded": "lg"
    }
  }, [_c('v-flex', [_c('h2', [_vm._v("Introducing CURE Pay")]), _c('p', {
    staticClass: "pr-10"
  }, [_vm._v(" The easy way to buy a projects token without any knowledge of wallets and exchanges.We handle everything in real-time, so that you don’t have to. Once completed, you will have your own secure dashboard to access your tokens. "), _c('br'), _c('br'), _vm._v(" The result is that you have an easy to manage place to store the crypto projects you support. If at any point you want to sell, we provide you with online educational resources to help with that process. ")]), _c('v-container', {
    staticClass: "mb-8",
    staticStyle: {
      "max-width": "600px"
    }
  }, [_c('v-timeline', {
    attrs: {
      "dense": "",
      "clipped": ""
    }
  }, [_c('v-timeline-item', {
    attrs: {
      "color": "orange",
      "icon-color": "grey lighten-2",
      "large": "",
      "fill-dot": ""
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text font-weight-black headline"
        }, [_vm._v("1")])];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "mt-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Choose An Amount")]), _c('span', {
    staticClass: "body-2"
  }, [_vm._v("Enter the amount of USD that you would like to buy.")])])], 1)], 1), _c('v-timeline-item', {
    attrs: {
      "color": "orange",
      "icon-color": "grey lighten-2",
      "large": "",
      "fill-dot": ""
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text font-weight-black headline"
        }, [_vm._v("2")])];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "mt-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Enter Payment Details")]), _c('span', {
    staticClass: "body-2"
  }, [_vm._v("You can buy via credit card, debit card, and even Apple Pay.")])])], 1)], 1), _c('v-timeline-item', {
    attrs: {
      "color": "orange",
      "icon-color": "grey lighten-2",
      "large": "",
      "fill-dot": ""
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text font-weight-black headline"
        }, [_vm._v("3")])];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "mt-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Receive Your Tokens")]), _c('span', {
    staticClass: "body-2"
  }, [_vm._v("You will get a confirmation email with your token.")])])], 1)], 1)], 1)], 1), _vm.projects ? _c('v-row', {
    staticClass: "mr-10"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "mt-10"
  }, [_vm._v("Available Projects")])]), _vm._l(_vm.projects, function (image, n) {
    return _c('v-col', {
      key: n,
      staticClass: "d-flex child-flex ma-0 pa-2",
      attrs: {
        "cols": "2"
      }
    }, [_c('v-img', {
      staticClass: "hand",
      attrs: {
        "src": _vm.projects[n][2],
        "aspect-ratio": "1",
        "contain": ""
      },
      on: {
        "click": function ($event) {
          return _vm.loadPage(_vm.projects[n][1]);
        }
      }
    })], 1);
  })], 2) : _vm._e(), _c('h2', {
    staticClass: "mt-10"
  }, [_vm._v("About CURE Pay")]), _c('p', {
    staticClass: "pr-10"
  }, [_vm._v(" CURE Pay is an on-ramp that allows your project to easily accept traditional payment methods. Unlike other on-ramps, CURE Pay transacts directly from traditional payment methods to your project. These transactions support CURE Token, CURE is dedicated to using the power of decentralized finance to help rid the planet of dreadful diseases like cancer. Partnering with global research teams and drawing from a wealth of experience in the innovation, technological, and charitable sectors, CURE aims to go one step further than simply becoming the world's most successful charity token. Learn more at "), _c('a', {
    attrs: {
      "href": "https://www.curetoken.net/",
      "target": "_blank"
    }
  }, [_vm._v("www.curetoken.net")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }