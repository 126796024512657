var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "bg",
    attrs: {
      "id": "bgImage"
    }
  }), _c('v-app-bar', {
    staticClass: "animated",
    class: {
      fadeIn: _vm.show
    },
    staticStyle: {
      "opacity": "0"
    },
    attrs: {
      "absolute": "",
      "color": _vm.barTransparent,
      "src": _vm.backgroundSRC,
      "elevation": "0"
    }
  }, [_c('v-container', {
    staticClass: "py-0 fill-height"
  }, [_c('v-spacer'), _vm.theme == 'cure' ? _c('v-app-bar-title', [_c('div', {
    staticClass: "mt-0",
    staticStyle: {
      "fill": "white"
    }
  }, [_c('svg', {
    staticClass: "InlineSVG Icon Icon--sm",
    attrs: {
      "viewBox": "0 0 78 17",
      "focusable": "false",
      "width": "125",
      "height": "50",
      "role": "img",
      "aria-labelledby": "stripe-title"
    }
  }, [_c('title', {
    attrs: {
      "id": "stripe-title"
    }
  }, [_vm._v("CURE Pay")]), _c('g', {
    attrs: {
      "fill-rule": "evenodd"
    }
  }, [_c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M5.26,3.9c1.92,0,4.23.67,4.23,2.44V7.87H6.75V7.29c0-.46-.62-.71-1.36-.71A2,2,0,0,0,3.24,8.84a2.25,2.25,0,0,0,2.43,2.44,4.89,4.89,0,0,0,2.88-1.06l1.09,2.25a6.43,6.43,0,0,1-4.18,1.47C2.61,13.94,0,12.08,0,9A5,5,0,0,1,5.26,3.9Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M11.79,6.45h-1.3V4.12H15V9.84c0,.9.35,1.27,1.06,1.27,1.27,0,2-1.47,2-2.92V6.45H16.77V4.12H21.3V11a.37.37,0,0,0,.41.41h.8v2.33h-3c-.88,0-1.33-.43-1.33-1.08v-.26a2.5,2.5,0,0,1,0-.28h0a3.5,3.5,0,0,1-3.27,1.84c-1.69,0-3.15-.89-3.15-3.59Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M23.76,11.39h1.3V6.86a.37.37,0,0,0-.41-.41h-.89V4.12h3c.88,0,1.36.36,1.36,1.23v.51a3.4,3.4,0,0,1,0,.48h0A3.35,3.35,0,0,1,31.21,4a1.94,1.94,0,0,1,.37,0V7.16a3.18,3.18,0,0,0-.65-.06c-1.79,0-2.65,1.14-2.65,2.89v1.4h1.31v2.33H23.76Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M37.36,3.9c2.87,0,4.47,2,4.47,4.64,0,.32-.07,1-.07,1h-6.1a2.14,2.14,0,0,0,2.24,1.77,5.33,5.33,0,0,0,2.7-.91l1.18,2.23a6.7,6.7,0,0,1-4.14,1.34,5,5,0,0,1-5.28-5A4.78,4.78,0,0,1,37.36,3.9ZM38.5,7.74a1.32,1.32,0,0,0-1.22-1.42,1.56,1.56,0,0,0-1.54,1.42Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M61.06,6.1H61a5.87,5.87,0,0,1-.19,1l-.51,1.77h1.44l-.5-1.77A5.87,5.87,0,0,1,61.06,6.1Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M53.56,6.22A1.44,1.44,0,0,0,52.72,6H51.66V8.74h1a1.61,1.61,0,0,0,.84-.2A1.34,1.34,0,0,0,54,7.36,1.33,1.33,0,0,0,53.56,6.22Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M74.83,0H47.72A3.33,3.33,0,0,0,44.4,3.32V14.53a3.33,3.33,0,0,0,3.32,3.31H74.83a3.32,3.32,0,0,0,3.31-3.31V3.32A3.32,3.32,0,0,0,74.83,0ZM54.53,10.42a4.72,4.72,0,0,1-1.51.2H51.66V12h1.07v1.75H48.18V12h1.07V5.87H48.18V4.12H53a4.52,4.52,0,0,1,1.61.22,3.09,3.09,0,0,1,1.85,3A3.13,3.13,0,0,1,54.53,10.42Zm11.28,3.3h-4V12h.83l-.36-1.27H59.8L59.44,12h.83v1.75h-4V12h.79l2.62-7.85H62.4L65,12h.79Zm8.31-7.85H73.3L71,9.79V12H72v1.75H67.47V12h1.08V9.79L66.2,5.87h-.82V4.12h4V5.87h-.8l1,1.77a4,4,0,0,1,.21.47h0A4,4,0,0,1,70,7.64l1-1.77h-.8V4.12h4Z"
    }
  })])])])]) : _c('v-app-bar-title', [_c('img', {
    staticStyle: {
      "display": "block",
      "margin": "auto",
      "margin-top": "10px",
      "top": "0px",
      "position": "relative"
    },
    attrs: {
      "src": "/img/alpha-logo.png",
      "height": "50",
      "width": "auto",
      "alt": "CURE Token Logo"
    }
  })]), _c('v-spacer'), _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "dark": "",
            "icon": ""
          }
        }, on), [_c('v-icon', [_vm._v("mdi-dots-vertical")])], 1)];
      }
    }])
  }, [_c('v-card', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm.$auth.isAuthenticated ? _c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-logout")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Logout")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "500"
    },
    model: {
      value: _vm.transferAlert,
      callback: function ($$v) {
        _vm.transferAlert = $$v;
      },
      expression: "transferAlert"
    }
  }, [_c('v-card', {
    staticClass: "pa-4"
  }, [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" Sure, we can help you with that. ")]), _c('v-card-text', [_vm._v("Please reach out to "), _c('a', {
    attrs: {
      "href": "mailto:support@curetoken.net"
    }
  }, [_vm._v("support@curetoken.net")]), _vm._v(" to request support on how to sell your tokens. Alternatively, you can request your private keys from the Pro Features Mode and import them into a software wallet of your choice.")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": _vm.primaryColor,
      "large": ""
    },
    on: {
      "click": function ($event) {
        _vm.transferAlert = false;
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1)], 1), _c('v-dialog', {
    staticClass: "pa-8",
    attrs: {
      "max-width": "500px"
    },
    model: {
      value: _vm.requestProFeatures,
      callback: function ($$v) {
        _vm.requestProFeatures = $$v;
      },
      expression: "requestProFeatures"
    }
  }, [_c('v-card', {
    staticClass: "pa-8"
  }, [_c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-row', {}, [_c('v-col', {
    staticClass: "pa-8",
    attrs: {
      "sm": "12",
      "cols": "12"
    }
  }, [_c('h2', [_vm._v(" Request With KYC ")]), _c('h4', {
    staticClass: "mb-4"
  }, [_vm._v(" Approximately 24 Hours. If needed, the CURE Pay team will reach out for additional KYC materials. ")]), _c('p', [_vm._v("This feature will allow you to transfer your tokens to a software wallet of your choice. You can enable this feature by completing a KYC that requires you to provide your ID for validation.")]), _c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": _vm.primaryColor,
      "elevation": "0",
      "block": "",
      "large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.request_kyc();
      }
    }
  }, [_vm._v(" Perform KYC ")])], 1)], 1)], 1)], 1)], 1), _vm.buyWidget ? _c('v-dialog', {
    attrs: {
      "max-width": "490"
    },
    model: {
      value: _vm.buyWidget,
      callback: function ($$v) {
        _vm.buyWidget = $$v;
      },
      expression: "buyWidget"
    }
  }, [_c('v-card', {
    staticStyle: {
      "border-radius": "20px"
    },
    attrs: {
      "fill-height": "",
      "id": "buyWidget"
    }
  }, [_c('Widget', {
    attrs: {
      "clientID": _vm.curToken,
      "user": _vm.user,
      "email": _vm.email,
      "embedded": true
    }
  })], 1)], 1) : _vm._e(), _c('v-container', {
    style: {
      margin: _vm.themeHeight
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "sm": "4",
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-fade-transition', [_c('v-sheet', {
    staticClass: "mb-4 mx-4 animated fadeIn",
    staticStyle: {
      "padding": "20px",
      "opacity": "0",
      "position": "relative"
    },
    attrs: {
      "rounded": "",
      "width": "100%",
      "elevation": "20"
    }
  }, [_c('v-progress-circular', {
    staticClass: "mb-4 mx-4 animated loader",
    class: {
      fadeOut: _vm.show
    },
    attrs: {
      "size": 40,
      "width": 5,
      "color": "grey lighten-3",
      "indeterminate": ""
    }
  }), _c('div', {
    staticClass: "animated",
    class: {
      fadeIn: _vm.show
    },
    staticStyle: {
      "opacity": "0"
    }
  }, [_c('v-list-item', {
    staticClass: "mb-4 ma-0 pa-0"
  }, [_vm.proMode ? _c('v-badge', {
    attrs: {
      "avatar": "",
      "bordered": "",
      "overlap": "",
      "bottom": "",
      "offset-x": "18",
      "offset-y": "18",
      "icon": "mdi-check",
      "color": "blue"
    }
  }, [_c('v-avatar', {
    staticClass: "profile-picture white--text",
    attrs: {
      "color": _vm.primaryColor,
      "size": "65"
    }
  }, [_c('h1', [_vm._v(_vm._s(_vm.user.initials))])])], 1) : _c('v-avatar', {
    staticClass: "profile-picture white--text",
    attrs: {
      "color": _vm.primaryColor,
      "size": "65"
    }
  }, [_c('h1', [_vm._v(_vm._s(_vm.user.initials))])]), _c('v-list-item-content', [_c('v-list-item-subtitle', [_c('div', {
    staticClass: "ma-4"
  }, [_c('h2', {
    staticClass: "grey--text text--darken-1",
    staticStyle: {
      "font-size": "1.75em",
      "line-height": "1em"
    }
  }, [_vm.toggleState ? _c('span', [_vm._v(_vm._s(_vm.user.fullname))]) : _c('span', [_vm._v("﹡﹡﹡ ﹡﹡﹡")]), _c('v-btn', {
    staticClass: "mx-2",
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "icon": "",
      "dark": "",
      "small": "",
      "color": "grey lighten-1"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleDetails();
      }
    }
  }, [_vm.toggleState ? _c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-eye-off ")]) : _c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v(" mdi-eye ")])], 1)], 1), _c('p', {
    staticClass: "grey--text text--darken-1 text-wrap caption"
  }, [_vm.toggleState ? _c('span', [_vm._v(_vm._s(_vm.shortenLength(_vm.email)))]) : _c('span', [_vm._v("﹡﹡﹡﹡@﹡﹡﹡.﹡﹡﹡")])])])])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    staticClass: "my-4"
  }, [_c('v-list-item-content', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('v-list-item-title', {
    staticClass: "grey--text text--darken-1"
  }, [_vm._v(" APPROXIMATE PORTFOLIO VALUE ")]), _c('v-list-item-subtitle', [_c('h1', {
    staticClass: "font-weight-light",
    style: {
      color: _vm.primaryColor
    }
  }, [_vm.toggleState ? _c('span', [_vm._v("$" + _vm._s(_vm.totalValue))]) : _c('span', [_vm._v("﹡﹡.﹡﹡")])])])], 1)], 1), !_vm.proMode ? _c('v-divider') : _vm._e(), !_vm.proMode ? _c('div', [_c('v-list-item', {}, [_c('v-list-item-content', {
    staticClass: "py-4",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('v-list-item-subtitle', [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "grey",
      "large": "",
      "block": "",
      "elevation": "0",
      "disabled": _vm.kycButtonDisabled
    },
    on: {
      "click": function ($event) {
        _vm.requestProFeatures = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-check-decagram ")]), _vm._v(" " + _vm._s(_vm.kycButtonText) + " ")], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), _vm.recoProjects.length > 1 ? _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-fade-transition', [_c('v-sheet', {
    staticClass: "mb-4 mx-4 animated fadeIn",
    staticStyle: {
      "padding": "20px",
      "opacity": "0",
      "position": "relative"
    },
    attrs: {
      "rounded": "",
      "width": "100%",
      "elevation": "20"
    }
  }, [_c('v-progress-circular', {
    staticClass: "mb-4 mx-4 animated loader",
    class: {
      fadeOut: _vm.show
    },
    attrs: {
      "size": 40,
      "width": 5,
      "color": "grey lighten-3",
      "indeterminate": ""
    }
  }), _c('div', {
    staticClass: "grey--text text--darken-1"
  }, [_c('h4', {
    staticClass: "mb-6 text-center"
  }, [_vm._v("PROJECT ECOSYSTEM")]), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, _vm._l(_vm.mainProjects, function (image, n) {
    return _c('v-col', {
      key: n,
      staticClass: "d-flex child-flex",
      attrs: {
        "cols": "4"
      }
    }, [_c('v-img', {
      staticClass: "hand",
      attrs: {
        "src": _vm.mainProjects[n][2],
        "aspect-ratio": "1",
        "contain": ""
      },
      on: {
        "click": function ($event) {
          return _vm.buyModal(_vm.mainProjects[n][1]);
        }
      }
    })], 1);
  }), 1), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, _vm._l(_vm.recoProjects, function (image, n) {
    return _c('v-col', {
      key: n,
      staticClass: "d-flex child-flex",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-img', {
      staticClass: "hand",
      attrs: {
        "src": _vm.recoProjects[n][2],
        "aspect-ratio": "1",
        "contain": ""
      },
      on: {
        "click": function ($event) {
          return _vm.buyModal(_vm.recoProjects[n][1]);
        }
      }
    })], 1);
  }), 1)], 1)], 1)], 1)], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "sm": "8",
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-sheet', {
    staticClass: "mx-4 animated fadeIn",
    staticStyle: {
      "padding": "20px",
      "opacity": "0",
      "position": "relative"
    },
    attrs: {
      "rounded": "",
      "width": "100%",
      "elevation": "20"
    }
  }, [_c('v-progress-circular', {
    staticClass: "mb-4 mx-4 animated loader",
    class: {
      fadeOut: _vm.show
    },
    attrs: {
      "size": 40,
      "width": 5,
      "color": "grey lighten-3",
      "indeterminate": ""
    }
  }), _c('div', {
    staticClass: "animated",
    class: {
      fadeIn: _vm.show
    },
    staticStyle: {
      "opacity": "0"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    staticStyle: {
      "min-height": "50px"
    },
    attrs: {
      "transparent": "",
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "mb-4"
  }, [_c('v-row', {
    attrs: {
      "align": "start"
    }
  }, [_c('div', {
    staticClass: "text-caption grey--text text-uppercase"
  }, [_c('p', {
    staticClass: "grey--text text--darken-1 ml-1 mb-0"
  }, [_vm._v(_vm._s(_vm.projectName) + " Current Price "), _vm.difference.includes('-') ? _c('span', {}, [_c('span', {
    staticClass: "red--text",
    domProps: {
      "textContent": _vm._s(_vm.difference || '-')
    }
  }), _c('v-icon', {
    staticClass: "red--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-menu-down")])], 1) : _c('span', {}, [_c('span', {
    staticClass: "green--text",
    domProps: {
      "textContent": _vm._s(_vm.difference || '-')
    }
  }), _c('v-icon', {
    staticClass: "green--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-menu-up")])], 1)]), _c('span', {
    staticClass: "text-h4 font-weight-light mt-0",
    style: {
      color: _vm.primaryColor
    },
    domProps: {
      "textContent": _vm._s(_vm.currentPrice || '—')
    }
  })]), _c('v-spacer'), _c('span', {
    staticClass: "text-caption grey--text text-uppercase text-right"
  }, [_c('span', {
    staticClass: "d-block red--text"
  }, [_vm._v("LOW: " + _vm._s(_vm.minNum.toFixed(9)) + " ")]), _c('span', {
    staticClass: "d-block green--text",
    staticStyle: {
      "line-height": ".9em"
    }
  }, [_vm._v("HIGH: " + _vm._s(_vm.maxNum.toFixed(9)))])])], 1)], 1), !_vm.$vuetify.breakpoint.smAndDown ? _c('v-sheet', {
    attrs: {
      "color": "transparent"
    }
  }, [_vm.value.length > 1 ? _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('v-sparkline', {
    ref: "sparkline",
    attrs: {
      "value": _vm.value,
      "gradient": _vm.gradient,
      "smooth": _vm.radius || false,
      "padding": _vm.padding,
      "line-width": _vm.width,
      "stroke-linecap": _vm.lineCap,
      "gradient-direction": _vm.gradientDirection,
      "type": _vm.type,
      "auto-line-width": _vm.autoLineWidth,
      "auto-draw": "",
      "height": _vm.sparkHeight,
      "color": "grey"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function (item) {
        return [_vm._v(" " + _vm._s(Number(item.value).toFixed(4)) + " ")];
      }
    }], null, false, 2846926091)
  })], 1) : _c('div', {
    style: {
      margin: _vm.chartMargin
    },
    attrs: {
      "align": "center",
      "justify": "center",
      "height": _vm.sparkHeight,
      "fill-height": ""
    }
  }, [_c('span', {
    staticStyle: {
      "color": "#cccccc"
    }
  }, [_vm._v("NO CHART DATA AVAILABLE")])])]) : _vm._e()], 1), _c('div', [!_vm.$vuetify.breakpoint.smAndDown ? _c('v-tabs', {
    staticClass: "mt-4",
    attrs: {
      "background-color": _vm.primaryColor,
      "dark": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', {
    staticClass: "tab",
    attrs: {
      "href": "#summary"
    }
  }, [_vm._v(" TOKEN SUMMARY ")]), _c('v-tab', {
    staticClass: "tab",
    attrs: {
      "href": "#purchase"
    }
  }, [_vm._v(" PURCHASE HISTORY ")]), _c('v-tab', {
    staticClass: "tab",
    attrs: {
      "href": "#transfer"
    }
  }, [_vm._v(" TRANSFER HISTORY ")]), _c('v-tabs-items', {
    attrs: {
      "touchless": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "summary",
      "transition": "fade-transition",
      "touchless": ""
    }
  }, [_vm.tab == 'summary' ? _c('Summary', {
    attrs: {
      "primaryColor": _vm.primaryColor,
      "user": _vm.user,
      "email": _vm.email,
      "proMode": _vm.proMode
    },
    on: {
      "projects": function ($event) {
        return _vm.storeProjects($event);
      },
      "buyModal": function ($event) {
        return _vm.buyModal($event);
      },
      "loadChart": function ($event) {
        return _vm.loadChart($event);
      },
      "refresh": function ($event) {
        return _vm.refreshData($event);
      },
      "requestProFeaturesEmit": function ($event) {
        return _vm.requestProFeaturesEmit($event);
      }
    }
  }) : _vm._e()], 1), _c('v-tab-item', {
    attrs: {
      "value": "purchase",
      "transition": "fade-transition",
      "touchless": ""
    }
  }, [_vm.tab == 'purchase' ? _c('Purchase', {
    attrs: {
      "primaryColor": _vm.primaryColor,
      "email": _vm.email
    }
  }) : _vm._e()], 1), _c('v-tab-item', {
    attrs: {
      "value": "transfer",
      "transition": "fade-transition",
      "touchless": ""
    }
  }, [_vm.tab == 'transfer' ? _c('Transfer', {
    attrs: {
      "primaryColor": _vm.primaryColor,
      "email": _vm.email,
      "proMode": _vm.proMode
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.$vuetify.breakpoint.smAndDown ? _c('v-expansion-panels', {
    attrs: {
      "accordion": "",
      "multiple": ""
    },
    model: {
      value: _vm.panel,
      callback: function ($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, [_c('v-expansion-panel', {
    attrs: {
      "expand": ""
    },
    model: {
      value: _vm.panel,
      callback: function ($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, [_c('v-expansion-panel-header', {
    staticClass: "white--text",
    attrs: {
      "color": _vm.primaryColor
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "white"
          }
        }, [_vm._v("$expand")])];
      },
      proxy: true
    }], null, false, 4099104739)
  }, [_vm._v("TOKEN SUMMARY")]), _c('v-expansion-panel-content', [_c('Summary', {
    attrs: {
      "primaryColor": _vm.primaryColor,
      "user": _vm.user,
      "email": _vm.email,
      "proMode": _vm.proMode
    },
    on: {
      "projects": function ($event) {
        return _vm.storeProjects($event);
      },
      "buyModal": function ($event) {
        return _vm.buyModal($event);
      },
      "loadChart": function ($event) {
        return _vm.loadChart($event);
      },
      "refresh": function ($event) {
        return _vm.refreshData($event);
      }
    }
  })], 1)], 1), _c('v-expansion-panel', {
    model: {
      value: _vm.panel,
      callback: function ($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, [_c('v-expansion-panel-header', {
    staticClass: "white--text",
    attrs: {
      "color": _vm.primaryColor
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "white"
          }
        }, [_vm._v("$expand")])];
      },
      proxy: true
    }], null, false, 4099104739)
  }, [_vm._v("PURCHASE HISTORY")]), _c('v-expansion-panel-content', [_c('Purchase', {
    attrs: {
      "primaryColor": _vm.primaryColor,
      "email": _vm.email
    }
  })], 1)], 1), _c('v-expansion-panel', {
    model: {
      value: _vm.panel,
      callback: function ($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, [_c('v-expansion-panel-header', {
    staticClass: "white--text",
    attrs: {
      "color": _vm.primaryColor
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "color": "white"
          }
        }, [_vm._v("$expand")])];
      },
      proxy: true
    }], null, false, 4099104739)
  }, [_vm._v("TRANSFER HISTORY")]), _c('v-expansion-panel-content', [_c('Transfer', {
    attrs: {
      "primaryColor": _vm.primaryColor,
      "email": _vm.email,
      "proMode": _vm.proMode
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-footer', {
    staticStyle: {
      "height": "90px"
    },
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm.theme == 'cure' ? _c('div', {
    staticClass: "Footer-PoweredBy mt-0"
  }, [_c('a', {
    staticClass: "Link Link--primary",
    attrs: {
      "href": "https://curetoken.net",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("Powered by "), _c('svg', {
    staticClass: "InlineSVG Icon Footer-PoweredBy-Icon Icon--md",
    staticStyle: {
      "fill": "grey"
    },
    attrs: {
      "focusable": "false",
      "width": "33",
      "height": "10",
      "role": "img",
      "aria-labelledby": "stripe-title"
    }
  }, [_c('title', {
    attrs: {
      "id": "stripe-title"
    }
  }, [_vm._v("CURE")]), _c('g', {
    attrs: {
      "fill-rule": "evenodd"
    }
  }, [_c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M4.26,5.55A1.68,1.68,0,0,1,2.44,3.71,1.49,1.49,0,0,1,4.05,2c.56,0,1,.18,1,.53V3H7.13V1.83C7.13.5,5.39,0,4,0A3.79,3.79,0,0,0,0,3.8,3.83,3.83,0,0,0,4.1,7.55,4.8,4.8,0,0,0,7.24,6.44L6.43,4.75A3.67,3.67,0,0,1,4.26,5.55Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M16,5.32V.17H12.6V1.92h1v1.3c0,1.09-.54,2.2-1.5,2.2-.53,0-.79-.28-.79-1V.17H7.88V1.92h1V4.85c0,2,1.1,2.7,2.37,2.7a2.61,2.61,0,0,0,2.45-1.39h0s0,.12,0,.21v.2c0,.49.33.81,1,.81h2.23V5.63h-.61A.28.28,0,0,1,16,5.32Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M21.16,1.83h0a1.89,1.89,0,0,0,0-.36V1.09c0-.66-.37-.92-1-.92H17.85V1.92h.67a.28.28,0,0,1,.31.31v3.4h-1V7.38h4.38V5.63h-1v-1c0-1.32.65-2.17,2-2.17a2.62,2.62,0,0,1,.49,0V.07a1.53,1.53,0,0,0-.28,0A2.48,2.48,0,0,0,21.16,1.83Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M28.48,5.55A1.61,1.61,0,0,1,26.8,4.22h4.58s.05-.49.05-.73A3.2,3.2,0,0,0,28.07,0a3.59,3.59,0,0,0-3.75,3.77,3.73,3.73,0,0,0,4,3.78,5,5,0,0,0,3.11-1l-.88-1.68A4,4,0,0,1,28.48,5.55ZM28,1.82a1,1,0,0,1,.91,1.07H26.85A1.19,1.19,0,0,1,28,1.82Z"
    }
  })])])])])]) : _vm._e(), _vm.theme == 'alpha' ? _c('div', {
    staticClass: "Footer-PoweredBy mt-0"
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("Powered by "), _c('svg', {
    staticClass: "InlineSVG Icon Footer-PoweredBy-Icon Icon--md",
    staticStyle: {
      "fill": "grey"
    },
    attrs: {
      "focusable": "false",
      "width": "120",
      "height": "14",
      "role": "img",
      "aria-labelledby": "stripe-title"
    }
  }, [_c('title', {
    attrs: {
      "id": "stripe-title"
    }
  }, [_vm._v("CURE")]), _c('g', {
    attrs: {
      "fill-rule": "evenodd"
    }
  }, [_c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M4,3.87c1.64,0,3.38.54,3.38,1.93V7.09H5V6.68c0-.26-.27-.4-.8-.4-1,0-1.23.63-1.23,1.3a1.35,1.35,0,0,0,1.5,1.5,3.41,3.41,0,0,0,2.13-.75l1,2a4.87,4.87,0,0,1-3.28,1.1C2,11.47,0,10.09,0,7.69A3.85,3.85,0,0,1,4,3.87Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M13.53,6.11h-.89V4h3.81V8.91a.29.29,0,0,0,.31.31h.53V11.3H14.72c-.69,0-1.05-.3-1.05-.89,0-.17,0-.35,0-.35h0a2.33,2.33,0,0,1-2.31,1.41c-1.21,0-2.43-.6-2.43-2.69V6.11H8.05V4h3.81V8.26c0,.49.17.66.53.66.77,0,1.14-.84,1.14-1.76Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M18.12,9.23h.93V6.42a.28.28,0,0,0-.3-.31h-.58V4h2.61c.67,0,1.08.27,1.08,1v.35a1.3,1.3,0,0,1,0,.31h0a2.53,2.53,0,0,1,2.31-1.77l.25,0V6.78a3.12,3.12,0,0,0-.53,0c-1.28,0-1.91.54-1.91,1.82v.67h.94V11.3h-4.8Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M28.67,3.87A3.3,3.3,0,0,1,32.2,7.31a7.15,7.15,0,0,1-.05.8H27.81a1.29,1.29,0,0,0,1.37,1,4.54,4.54,0,0,0,2-.63l1,2a5.39,5.39,0,0,1-3.24,1,3.78,3.78,0,0,1-4.09-3.86A3.64,3.64,0,0,1,28.67,3.87Zm.54,3c0-.49-.12-1-.63-1s-.69.74-.72,1Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M40.54,0h2.4V13.66h-2.4Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M51.1,9.15h.76l2.53-7.92h3.46l2.54,7.92h.77V11.3H56.6V9.15h.79l-.2-.93H55.05l-.2.93h.79V11.3H51.1Zm5.63-2.92-.32-1.38c-.17-.75-.25-1.35-.25-1.35h-.07a10.81,10.81,0,0,1-.26,1.35L55.5,6.23Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M62,3.31h-.89V1.23H64.9v7c0,.72.12.88.64.88h.25v2.3a7.75,7.75,0,0,1-.79,0c-1.41,0-3-.27-3-3.06Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M67.35,12V6.42a.28.28,0,0,0-.3-.31h-.58V4H69c.58,0,1,.17,1,.69a1,1,0,0,1,0,.14h0a2.63,2.63,0,0,1,2.17-1c1.88,0,3.25,1.55,3.25,3.78s-1.5,3.82-3.35,3.82a2.61,2.61,0,0,1-1.76-.6h0a3.75,3.75,0,0,1,0,.55V12h.82v2.08H66.54V12Zm3.94-2.95c.64,0,1.15-.48,1.15-1.36S72,6.3,71.32,6.3s-1.14.45-1.14,1.38C70.18,8.4,70.53,9.08,71.29,9.08Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M76.06,9.23h.81V3.31H76V1.23H79.8v3.1a4.4,4.4,0,0,1,0,.63h0a2.58,2.58,0,0,1,4.68,1.6V9.23h.81V11.3H81.54V7.13c0-.49-.18-.63-.55-.63-.91,0-1.19.85-1.19,1.67V9.23h.81V11.3H76.06Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M89.49,6.91h.42V6.58c0-.33-.39-.46-.79-.46a4.44,4.44,0,0,0-2,.69l-1-2a5.85,5.85,0,0,1,3.31-1c2.11,0,3.36,1.09,3.36,3v2a.29.29,0,0,0,.31.31h.44V11.3H91.2a.92.92,0,0,1-1-.89v0a.8.8,0,0,1,0-.11h0a2.29,2.29,0,0,1-2.06,1.15,2.2,2.2,0,0,1-2.34-2.14C85.78,7.2,88.4,6.91,89.49,6.91Zm-.41,2.62a1,1,0,0,0,.87-1V8.21h-.17c-.88,0-1.19.42-1.19.8A.48.48,0,0,0,89.08,9.53Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M97.79,9.15h.73l2.44-3L98.67,3.39h-.81V1.23h4.51V3.39h-.64l.63.76a4.51,4.51,0,0,1,.35.5h0s.13-.21.35-.5l.61-.76h-.63V1.23h4.23V3.39h-.73l-2.41,2.94,2.35,2.82h.76V11.3h-4.53V9.15h.72l-.7-.84a5.19,5.19,0,0,1-.35-.51h0s-.14.23-.35.51l-.63.84h.67V11.3H97.79Z"
    }
  })])])])]) : _vm._e()])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }