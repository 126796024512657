<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
  .v-application{
    background: none !important;
  }
</style>
<script>
export default {
  name: 'App',
  data: () => ({
    theme: false,
  }),
  methods: {
    buy() {
      this.$router.push('/');
    },
  },
};
</script>
