var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "min-height": "200px"
    },
    attrs: {
      "id": "transfer"
    }
  }, [_c('v-progress-circular', {
    staticClass: "mb-4 mx-4 animated loader",
    class: {
      fadeOut: _vm.show
    },
    attrs: {
      "size": 40,
      "width": 5,
      "color": "grey lighten-3",
      "indeterminate": ""
    }
  }), _vm.userData.length ? _c('v-data-table', {
    staticClass: "animated fadeIn",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.transfers,
      "items-per-page": 5,
      "id": "tableSummary",
      "mobile-breakpoint": "960"
    },
    scopedSlots: _vm._u([{
      key: "item.image",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('img', {
          staticStyle: {
            "margin-top": "5px",
            "width": "50px",
            "height": "50px"
          },
          attrs: {
            "src": item.image
          }
        })];
      }
    }], null, false, 81838915)
  }) : _vm._e(), !_vm.userData.length ? _c('v-container', {
    staticClass: "animated",
    class: {
      fadeIn: _vm.show
    },
    staticStyle: {
      "min-height": "200px",
      "opacity": "0"
    },
    attrs: {
      "fill-height": "",
      "fluid": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [!_vm.proMode ? _c('v-col', {
    staticClass: "text-center"
  }, [_vm._v("You can request this feature by performing a KYC.")]) : _c('v-col', {
    staticClass: "text-center"
  }, [_vm._v("No transfers have been requested.")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }