var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {}, [_vm.backgroundImg ? _c('v-parallax', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    attrs: {
      "fill-height": "",
      "dark": "",
      "src": _vm.backgroundImg,
      "height": "600"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-slide-y-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }]
  }, [_c('v-container', [_c('v-img', {
    staticStyle: {
      "margin": "0 auto",
      "padding": "0px"
    },
    attrs: {
      "max-height": "150",
      "max-width": "150",
      "src": this.tokenIcon,
      "id": "logo"
    }
  }), _c('h1', {
    staticClass: "text-h2 font-weight-thin mt-4"
  }, [_vm._v(" Buying crypto is difficult. ")]), _c('h2', {
    staticClass: "my-4 font-weight-light"
  }, [_vm._v(" That’s why we’ve made it easy. Use traditional payment methods and secure your piece of " + _vm._s(_vm.tokenName) + " Token today. ")])], 1)], 1)])], 1)], 1)], 1) : _vm._e(), _c('v-main', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "animated my-12",
    class: {
      fadeIn: _vm.show
    }
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "sticky"
  }, [_c('Widget', {
    staticClass: "mb-12",
    staticStyle: {
      "max-width": "525px"
    },
    attrs: {
      "clientID": _vm.clientID
    },
    on: {
      "getProject": _vm.getProject
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "5",
      "order-sm": "first",
      "order-md": "first"
    }
  }, [_c('v-sheet', {
    staticClass: "ma-4",
    attrs: {
      "min-height": "50vh",
      "rounded": "lg"
    }
  }, [_c('v-flex', [_c('h2', [_vm._v("Introducing CURE Pay for " + _vm._s(_vm.tokenName) + " Token.")]), _c('p', {
    staticClass: "pr-10"
  }, [_vm._v(" The easy way to buy " + _vm._s(_vm.tokenName) + " Token without any knowledge of wallets and exchanges. We handle everything in real-time, so that you don’t have to. Once completed, you will have your own secure dashboard to access your tokens. "), _c('br'), _c('br'), _vm._v(" The result is that you have an easy to manage place to store the crypto projects you support. If at any point you want to sell, we provide you with online educational resources to help with that process. ")]), _c('v-container', {
    staticClass: "mb-8",
    staticStyle: {
      "max-width": "600px"
    }
  }, [_c('v-timeline', {
    attrs: {
      "dense": "",
      "clipped": ""
    }
  }, [_c('v-timeline-item', {
    attrs: {
      "color": _vm.primaryColor,
      "icon-color": "grey lighten-2",
      "large": "",
      "fill-dot": ""
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text font-weight-black headline"
        }, [_vm._v("1")])];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "mt-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Choose an Amount ")]), _c('span', {
    staticClass: "body-2"
  }, [_vm._v("Enter the amount of USD that you would like to buy.")])])], 1)], 1), _c('v-timeline-item', {
    attrs: {
      "color": _vm.primaryColor,
      "icon-color": "grey lighten-2",
      "large": "",
      "fill-dot": ""
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text font-weight-black headline"
        }, [_vm._v("2")])];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "mt-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Enter Your Payment Details")]), _c('span', {
    staticClass: "body-2"
  }, [_vm._v("You can buy via credit card, debit card, and even Apple Pay.")])])], 1)], 1), _c('v-timeline-item', {
    attrs: {
      "color": _vm.primaryColor,
      "icon-color": "grey lighten-2",
      "large": "",
      "fill-dot": ""
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text font-weight-black headline"
        }, [_vm._v("3")])];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "mt-6",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Receive Your Tokens")]), _c('span', {
    staticClass: "body-2"
  }, [_vm._v("You will get a confirmation email with your token.")])])], 1)], 1)], 1)], 1), _c('h2', [_vm._v("About CURE Pay")]), _c('p', {
    staticClass: "pr-10"
  }, [_vm._v(" CURE Pay transacts directly from traditional payment methods to " + _vm._s(_vm.tokenName) + " Token. These transactions support CURE, CURE is dedicated to using the power of decentralized finance to help rid the planet of dreadful diseases like cancer. Partnering with global research teams and drawing from a wealth of experience in the innovation, technological, and charitable sectors, CURE aims to go one step further than simply becoming the world's most successful charity token. Learn more at "), _c('a', {
    staticStyle: {
      "color": "black !important"
    },
    attrs: {
      "href": "https://www.curetoken.net/",
      "target": "_blank"
    }
  }, [_vm._v("www.curetoken.net")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }