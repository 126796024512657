var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return this.$parent.$parent.$parent.currentStep == 4 ? _c('v-card', {
    staticClass: "text-center",
    attrs: {
      "id": "complete"
    }
  }, [_c('v-card-text', [_c('v-container', {
    staticStyle: {
      "width": "90%",
      "max-width": "600px",
      "text-align": "left"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("Thank you for your purchase.")]), _c('p', {
    staticClass: "py-5"
  }, [_vm._v(" Your tokens have been secured and can be accessed at any time. Shortly, you'll receive an email with your confirmation receipt. ")]), _c('v-row', {
    staticClass: "py-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr')]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('p', {
    staticClass: "text-center overline descTitle"
  }, [_vm._v("AMOUNT PAID")]), _c('p', {
    staticClass: "text-center descDetails"
  }, [_vm._v(_vm._s(this.amount))])]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('p', {
    staticClass: "text-center overline descTitle"
  }, [_vm._v("DATE PAID")]), _c('p', {
    staticClass: "text-center descDetails"
  }, [_vm._v(_vm._s(this.formatDate(this.date)))])]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('p', {
    staticClass: "text-center overline descTitle"
  }, [_vm._v("TOKENS")]), _c('p', {
    staticClass: "text-center descDetails"
  }, [_vm._v(_vm._s(this.numberWithCommas(this.tokens)))])]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('p', {
    staticClass: "text-center overline descTitle"
  }, [_vm._v("PAYMENT METHOD")]), _c('p', {
    staticClass: "text-center descDetails"
  }, [_vm._v(_vm._s(this.method))])])], 1), _c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr')]), _vm.newUser == 'false' ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.embedded ? _c('h3', {
    staticClass: "text-center mb-6"
  }, [_vm._v("You can login to your dashboard at any time to view your tokens.")]) : _vm._e(), _c('v-btn', {
    staticStyle: {
      "color": "white !important"
    },
    attrs: {
      "color": _vm.primaryColor,
      "dark": "",
      "block": "",
      "elevation": "0",
      "x-large": "",
      "href": "https://www.curepayapp.com/dashboard",
      "target": "_blank"
    }
  }, [_vm._v("Proceed To Dashboard")])], 1) : _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.embedded ? _c('h3', {
    staticClass: "text-center mb-6"
  }, [_vm._v("In order to access your tokens, please create a password by clicking the link in your welcome email. ")]) : _vm._e()])], 1), _c('v-row', {
    staticClass: "mt-10"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center mb-0"
  }, [_vm._v("If you have any questions, contact us at "), _c('a', {
    style: {
      color: _vm.primaryColor
    },
    attrs: {
      "href": "mailto:support@curepayapp.com"
    }
  }, [_vm._v("support@curepayapp.com")])])])], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }