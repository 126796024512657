var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return this.$parent.$parent.$parent.currentStep == 3 ? _c('v-card', {
    staticStyle: {
      "min-height": "550px"
    },
    attrs: {
      "id": "process",
      "ripple": false
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-container', {
    staticStyle: {
      "width": "90%",
      "max-width": "600px",
      "text-align": "left"
    }
  }, [_c('v-list', {
    staticStyle: {
      "min-height": "266px"
    },
    attrs: {
      "flat": "",
      "disable": ""
    }
  }, [_c('v-list-item-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.inProgress,
      expression: "inProgress"
    }]
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('h2', {
    staticClass: "mt-8"
  }, [_vm._v("Your transaction is already underway")]), _c('p', {
    staticClass: "my-2"
  }, [_vm._v("Your transaction has already started. If you do not recieve a confirmation email, please contact support at support@curepayapp.com.")])])], 1)], 1), _c('v-list-item-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.processingError,
      expression: "processingError"
    }]
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('h2', {
    staticClass: "mt-8"
  }, [_vm._v("An error has occurred.")]), _vm.error ? _c('p', {
    staticClass: "my-2"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e(), _c('p', {
    staticClass: "my-2"
  }, [_vm._v("The transaction process has failed, but don't worry, this can be resolved. Please contact support at support@curepayapp.com to report and resolve the issue.")]), _c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "color": _vm.primaryColor,
      "dark": "",
      "block": "",
      "elevation": "0",
      "x-large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.retry();
      }
    }
  }, [_vm._v("Retry Transaction")])], 1)], 1)], 1), _c('v-list-item-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loaded,
      expression: "loaded"
    }]
  }, [_c('v-list-item', {
    attrs: {
      "ripple": false
    }
  }, [_c('v-list-item-content', [_c('h2', {
    staticClass: "mt-8"
  }, [_vm._v("This process may take a few seconds.")]), _c('p', {
    staticClass: "my-2"
  }, [_vm._v("You can leave this screen. An email will be sent to you upon completion of the transaction with details on how to access your tokens.")])])], 1), _c('v-divider', {
    staticClass: "my-4"
  }), _vm._l(_vm.steps, function (step, i) {
    return _c('v-list-item', {
      key: i,
      staticClass: "px-10",
      attrs: {
        "ripple": false
      }
    }, [_c('v-list-item-icon', {
      staticClass: "my-2"
    }, [step.complete ? _c('v-icon', {
      attrs: {
        "color": _vm.primaryColor,
        "size": "40"
      }
    }, [_vm._v(" mdi-checkbox-marked-circle ")]) : _vm.currentStep == i ? _c('v-progress-circular', {
      attrs: {
        "color": _vm.primaryColor,
        "size": "40",
        "indeterminate": ""
      }
    }) : _c('v-icon', {
      attrs: {
        "size": "40",
        "color": "grey lighten-2"
      }
    }, [_vm._v("mdi-checkbox-marked-circle")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(step.title)
      }
    })], 1)], 1);
  })], 2)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }