<template>
  <v-card id="process" v-if="this.$parent.$parent.$parent.currentStep == 3" :ripple="false" style="min-height:550px">
    <v-card-text class="pa-0" >
      <v-container style="width: 90%; max-width: 600px; text-align: left;">
        <v-list flat disable style="min-height:266px;">
          <v-list-item-group
            v-show="inProgress"
          >
            <v-list-item>
              <v-list-item-content>
                <h2 class="mt-8">Your transaction is already underway</h2>
                <p class="my-2">Your transaction has already started. If you do not recieve a confirmation email, please contact support at support@curepayapp.com.</p>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group
            v-show="processingError"
          >
            <v-list-item>
              <v-list-item-content>
                <h2 class="mt-8">An error has occurred.</h2>
                <p v-if="error" class="my-2">{{error}}</p>
                <p class="my-2">The transaction process has failed, but don't worry, this can be resolved. Please contact support at support@curepayapp.com to report and resolve the issue.</p>
                <v-btn
                  :color="primaryColor"
                  dark
                  block
                  elevation="0"
                  x-large
                  class="mt-4"
                  @click="retry()"
                >Retry Transaction</v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group
            v-show="loaded"
          >
            <v-list-item :ripple="false">
              <v-list-item-content>
                <h2 class="mt-8">This process may take a few seconds.</h2>
                <p class="my-2">You can leave this screen. An email will be sent to you upon completion of the transaction with details on how to access your tokens.</p>
            </v-list-item-content>
            </v-list-item >
            <v-divider class="my-4"></v-divider>
            <v-list-item
              v-for="(step, i) in steps"
              :key="i"
              class="px-10"
              :ripple="false"
            >
              <v-list-item-icon class="my-2">
                <v-icon v-if="step.complete" :color="primaryColor" size="40">
                  mdi-checkbox-marked-circle
                </v-icon>
                <v-progress-circular
                  v-else-if="currentStep == i"
                  :color="primaryColor"
                  size="40"
                  indeterminate
                ></v-progress-circular>
                <v-icon v-else size="40" color="grey lighten-2">mdi-checkbox-marked-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="step.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

export default {
  data() {
    return {
      loaded: false,
      processingError: false,
      inProgress: false,
      previousBalance: "",
      BNBtokenHash: "",
      CUREtokenHash: "",
      myCURETimeout: "",
      myBNBTimeout: "",
      primaryColor: "",
      error:"",
      checkoutStatus:null,
      transactionID:'',
      domainPath:'',
      steps: [
        {
          title: 'Processing Payment',
          complete: true,
        },
        {
          title: 'Creating Account',
          complete: false,
        },
        {
          title: 'Acquiring Your Tokens',
          complete: false,
        },
        {
          title: 'Transaction Confirmed',
          complete: false,
        },
      ],
      currentStep: 1,
    };
  },
  watch: {
    processingError() {
      this.loaded = false;
      this.processingError = true;
      this.inProgress = false;
    },
    inProgress() {
      this.loaded = false;
      this.processingError = false;
      this.inProgress = true;
    }
  },
  computed: {
    purchased: {
      get: function(){
        return this.$store.state.purchased
      },
      set: function(val){
        this.$store.dispatch('SET_PURCHASED', val);
      },
    },
  },
  beforeDestroy () {
    clearInterval(this.checkoutStatus);
  },
  methods: {
    retry(){
      this.$parent.$parent.$parent.currentStep = 2;
    },
    pollCheckout() {
      this.checkoutStatus = setInterval(() => {
        console.log("CHECK TRANSACTION STATUS");

        fetch(`${this.$domainPath}/get-checkout-status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 'token': this.transactionID }),
        })
          .then((result) => result.json())
          .then((data) => {          
            console.log(data);
            console.log(data['status'])

            if(data['status'] == 'user-complete'){
              this.steps[1].complete = true;
              this.currentStep = 2;
            }

            if(data['status'] == 'transaction-in-progress'){
              this.steps[2].complete = true;
              this.currentStep = 3;
            }

            if(data['status'] == 'transaction-complete'){
              clearInterval(this.checkoutStatus);
              this.steps[3].complete = true;

              this.$parent.$parent.$parent.reciept['amount'] = data.response.amount
              this.$parent.$parent.$parent.reciept['date'] = data.response.date
              this.$parent.$parent.$parent.reciept['method'] = data.response.payment
              this.$parent.$parent.$parent.reciept['desc'] = data.response.description
              this.$parent.$parent.$parent.reciept['tokens'] = data.response.tokens
              this.$parent.$parent.$parent.reciept['newUser'] = data.response.newUser

              setTimeout(() => { 
                this.$parent.$parent.$parent.currentStep = 4; 
              }, 2000);
            }

            if(data['status'] == 'error'){
              clearInterval(this.checkoutStatus);
              console.log("ERROR")
              this.processingError = true;
              this.error = decode(data['message'])
            }

            if(data['status'] == 'failure'){
              clearInterval(this.checkoutStatus);
              console.log("FAILURE")
              this.processingError = true;
              this.error = decode(data['message'])
            }

          });
      }, 2000);
    },
  },
  mounted() {
    this.primaryColor = this.$parent.$parent.$parent.primaryColor;
    
    this.loaded = true;
    this.steps[0].complete = false;
    this.currentStep = 0;

    const params = new URLSearchParams(window.location.search);

    console.log(this.$domainPath)
    this.domainPath = this.$domainPath;
    console.log(this.domainPath)

    fetch(`${this.$domainPath}/checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ purchase_amount: this.$parent.$parent.$parent.purchaseAmount, 'uuid': this.$parent.$parent.$parent.uuid, 'token': this.$parent.$parent.$parent.token, 'customer': this.$parent.$parent.$parent.customer }),
      })
        .then((result) => result.json())
        .then((data) => {
          console.log(data);
          
          if(data['status'] == 'success'){
            this.transactionID = data['token'];
            console.log(this.transactionID);

            if(data['message'] == "initiated"){
              this.steps[0].complete = true; 
              this.currentStep = 1;
            }

            this.pollCheckout();
          }else{
            clearInterval(this.checkoutStatus);
            console.log("FAILURE")
            this.processingError = true;
            this.error = data['message']
          }
          return;
        });
  },
}
 
</script>

<style>
  .v-list-item__icon{
    margin-right:10px !important;
    padding-right:10px;
  }
  #process {
    background-color: transparent;
  }
</style>
