<template>
  <v-card class="text-center" id="calculate" v-if="this.$parent.$parent.$parent.currentStep == 1 && this.show" >
    
    <v-card-text class="pb-0">
      <!--<h2 class="my-10">UNDER MAINTENANCE</h2>-->
      <v-text-field
        v-model="purchaseAmount"
        prepend-inner-icon="mdi-currency-usd"
        height="100"
        style="
          width: fit-content;
          block-size: fit-content;
          margin: 0 auto;
          font-size: 100px;
          font-weight: bold;
          text-align: center;"
        :color="primaryColor"
        :class="`pb-4 custom-placeholder-color ma-4`"
        id="purchaseInput"
        :placeholder="minbuy"
        clearable
        type="number"
        hide-spin-buttons
        required
        :oninput="purchaseOnInput"
        :rules="purchaseRules"
        v-on:keypress="isNumber($event)"
      ></v-text-field>
      <div style="display:inline-block">
        <strong id="totalToken" style="font-size: 18px;">
          <div style="display: inline-block; text-align: right;">~{{numberWithCommas(tokenAmount.toFixed(2))}} {{this.tokenName}}
            <br><p style="font-size: .6em; float: right">(Actual token amount will vary.)</p></div>
          <v-img
            style="float: right; margin-top: -10px; margin-left: 10px;"
            max-height="65"
            max-width="65"
            :src="this.tokenIcon"
            eager
          ></v-img>
        </strong>
      </div>
      <p id="summary" class="py-5 my-4" >
        <table style="width:90%; margin: auto;">
          <tr>
            <td style="text-align: left">Approx Token Price</td>
            <td style="text-align: right">${{currentPrice}}</td>
          </tr>
          <tr>
            <td style="text-align: left">Processing Fees</td>
            <td style="text-align: right">{{processingFees}}%</td>
          </tr>
          <tr>
            <td class="font-weight-bold" style="text-align: left">Total Value</td>
            <td class="font-weight-bold" style="text-align: right">
              ${{ numberWithCommas((tokenAmount * currentPrice).toFixed(2)) }}
            </td>
          </tr>
        </table>
      </p>
      <div class="mt-4 mx-4">
      <v-checkbox
        v-model="checked"
        :rules="[v => !!v || 'You must agree in order to continue.']"
        label="I agree to the terms and conditions"
        required
        @click="dialog = true"
        >
      </v-checkbox>

      <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="500px"
      >
        <v-card style="padding:10px;">
          <v-card-title class="text-h5 mb-0" style="word-break: break-word;">
            Agree to the Terms and Conditions
          </v-card-title>
          <v-card-text style="height: 300px;">
            <p class="text-left" style="margin-bottom: 10px; font-size: 12px; line-height: 1.5em;">
              {{terms}}
            </p>
            <p class="text-left" style="margin-bottom: 10px; font-size: 12px; line-height: 1.5em;">
              Purchasing and selling tokens is inherently risky and holders must bear their risk of loss. Neither the authors of this website nor any participants in the {{tokenName}} project accept any liability for losses or taxes that holders, purchasers or sellers of {{tokenName}} may incur. The value of {{tokenName}} may decrease and may be highly volatile.
            </p>
            <p class="text-left" style="margin-bottom: 10px; font-size: 12px; line-height: 1.5em;">This website is not directed towards any person located in a jurisdiction where purchasing, selling or holding {{tokenName}} is prohibited or regulated. Consult your local laws before transacting in any cryptocurrency.</p>
          </v-card-text>
          <v-card-actions class="mt-4">
            <v-spacer></v-spacer>
            <v-btn
              color="grey darken-1"
              text
              @click="dialog = false, checked = false"
            >
              Disagree
            </v-btn>
            <v-btn
              :color="primaryColor"
              dark
              elevation="0"
              @click="dialog = false, checked = true"
            >
              Agree
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-btn
        class="white--text"
        :color="primaryColor"
        x-large
        block
        :disabled="buttonMaxBuy()"
        @click="purchase"
        style="margin-top:10px;"
      >
        CHECKOUT NOW
      </v-btn>
      
      <span id="hdnBtn">
        <v-btn
          class="white--text md-elevation-0"
          style="box-shadow: none; width:5px;"
          small
          width="5"
          height="5"
          @click="purchase"
        >
        </v-btn>
      </span>
      <div class="pb-6"></div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      currentStep:null,
      price: [],
      polling: null,
      tokenName: '',
      primaryColor: '',
      purchaseAmount: '',
      checked: false,
      clicked: false,
      dialog: false,
      show: false,
      purchaseRules: [
        v => +v >= 25 || '$25 Minimum',
        v => +v <= 500 || '$500 Maximum'
      ],
      purchaseOnInput: "if(this.value.length > Number(3)) this.value = this.value.slice(0, 3);",
      terms: 'Transaction fees apply. Approximate price and value subject to change during the purchase process. Access to your tokens is limited for a period of time, until a mandatory KYC is performed. After this point, you can request your private keys within your secure dashboard. By investing in any cryptocurrenty, understand that your investment can go up or down.',
      currentPrice: 0,
      processingFees: 0,
      transactionFees: 10,
      credentials: {
        sandbox: 'AViev34t2tORZy7myUsykqI-NWjUl1936pcNvkFlSHXEMfk0zWuc4d_cIhWZxu8UzuYzBLf5CWUDth9S',
        production: 'AViev34t2tORZy7myUsykqI-NWjUl1936pcNvkFlSHXEMfk0zWuc4d_cIhWZxu8UzuYzBLf5CWUDth9S',
      },
      experienceOptions: {
        input_fields: {
          no_shipping: 1
        }
      },
      myStyle: {
        size:  'responsive',
        shape: 'rect',
        color: 'blue'
      }
    };
  },
  watch: {
    purchaseAmount(val) {
      //this.purchaseAmount = val.replace('.','');
      if(val.charAt(0) === '0')
      {
        this.purchaseAmount = val.substring(1);

      }
    },
    currentStep(){
      //console.log("test");
    },    
  },
  props:[
    'maxbuy',
    'minbuy',
  ],
  computed: {
    tokenAmount() {
      return (
        (+this.purchaseAmount / this.currentPrice)
        * (1 - this.processingFees / 100)
      ) || 0;
    },
    
  },
  methods: {
    buttonMaxBuy() {
      if(this.purchaseAmount === '' || this.currentPrice === 0 || +this.purchaseAmount < this.minbuy || +this.purchaseAmount > this.maxbuy || !this.checked) return true;
      return false;
    },
    state(){
      //console.log("CALCULATE : STATE");
      //console.log(this.$parent.$parent.$parent.currentStep);
      return this.$parent.$parent.$parent.currentStep;
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if(/^[0-9]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },
    numberWithCommas(string) {
      return string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    purchase(){
      this.clicked=true;
      this.$emit('purchase', this.purchaseAmount);
    },
    onApprove(data, actions) {
      return actions.order.capture().then(details => {
        //console.log(details);
        this.$router.push({ path: '/', query: { id: details.id }})
        location.reload();
      });
    },
  },
  created() {
    //console.log("CALCULATE : created");
    //console.log(this.$parent.$parent.$parent.currentStep)
    //console.log(this.currentStep)

    this.tokenName = this.$parent.$parent.$parent.tokenName;
    this.price = this.$parent.$parent.$parent.price;
    this.currentPrice = this.$parent.$parent.$parent.currentPrice;
    this.processingFees = this.$parent.$parent.$parent.processingFees;
    this.primaryColor = this.$parent.$parent.$parent.primaryColor;
    this.tokenIcon = this.$parent.$parent.$parent.tokenIcon;


    this.purchaseRules = [
      v => +v >= this.minbuy || '$'+this.numberWithCommas(this.minbuy)+' Minimum',
      v => +v <= this.maxbuy || '$'+this.numberWithCommas(this.maxbuy)+' Maximum'
    ];
    
    if(this.maxbuy.length > 3){
      this.purchaseOnInput = "if(this.value.length > Number(4)) this.value = this.value.slice(0, 4);";
    }else{

    }
    this.show = true;
  },
  mounted() {
    //console.log("CALCULATE : mounted");
    function loadScript(url, callback) {
      const el = document.querySelector(`script[src="${url}"]`);
      if (!el) {
        const s = document.createElement('script');
        s.setAttribute('src', url); s.onload = callback;
        document.head.insertBefore(s, document.head.firstElementChild);
      }
    }
  },
  updated() {
    //console.log("CALCULATE : updated");
  },
};
</script>

<style>

  /*hdnBtn*/


  #hdnBtn .v-btn  {
    opacity: 0;
  }
  
  #calculate #summary {
    /*border: 1px #f1f1f1 solid;*/
    line-height: 150%; 
    border-radius: 20px;
    color: white; 
    margin: 0 15px;
    background-color: #444444;
  } 

  #calculate {
    background-color: transparent;
  }

  #calculate .v-label{
    font-size: .9em;
  }

  .v-card__title.text-h5.mb-0 {
    line-height: 1.2em;
  }

  #purchaseInput  {
    text-align: center !important;
    max-height: 120px;
  }
  #purchaseInput .v-text-field input {
    padding: 0;
  }
  #purchaseInput .v-text-field__slot {
    padding-bottom: 25px;
  }

  .custom-placeholder-color input::placeholder {
    opacity: .25;
  }

  #totalToken{
    float: right;
    margin-top: -10px;
  }

  .v-dialog__content{
    /*height: 600px !important;*/
  }

</style>
