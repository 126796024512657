<template>
  <div style="min-height:200px;" id="transfer">
    
    <v-progress-circular
      :size="40"
      :width="5"
      color="grey lighten-3"
      indeterminate
      class="mb-4 mx-4 animated loader"
      v-bind:class="{ fadeOut: show }"
    ></v-progress-circular>

    <v-data-table v-if="userData.length" class="animated fadeIn" 
      :headers="headers"
      :items="transfers"
      :items-per-page="5"
      id="tableSummary"
      mobile-breakpoint="960"
    >
      <template v-slot:item.image="{ item }">
        <img :src="item.image" style="margin-top: 5px; width: 50px; height: 50px" />
      </template>
    </v-data-table>

    <v-container fill-height fluid style="min-height:200px; opacity: 0;" class="animated" v-bind:class="{ fadeIn: show }" v-if="!userData.length">
      <v-row align="center"
        justify="center">
        <v-col v-if="!proMode" class="text-center">You can request this feature by performing a KYC.</v-col>
        <v-col v-else class="text-center">No transfers have been requested.</v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script scoped>

export default {
  props:[
    'email',
    'proMode',
    'primaryColor'
  ],
  data () {
    //console.log("TRANSFER : data");
    return {
      userData:{},
      loading: true,
      firstLoad: true,
      show: false,
      headers: [
        {
          text: "",
          align: "left",
          sortable: false,
          value: "image",
          width: "1"
        },
        {
          text: 'NAME',
          align: 'start',
          value: 'project',
          width: "100",
          sortable: false,
        },
        { text: 'TOKENS', value: 'tokens', sortable: false, },
        { text: 'WALLET', value: 'wallet', sortable: false, },
        { text: 'TRANSFERED', value: 'transfered', sortable: false, },

      ],
      transfers: [],
    };
  },
  created() {
    //console.log("TRANSFER : created");
    this.get_user_transfers(this.email);
  },
  methods: {
    async get_user_transfers(userEmail) {
      //console.log("get_user_transfers");
      const path = `${this.$domainPath}/get-transfer-data`;
      const token = await this.$auth.getTokenSilently();
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email: userEmail }),
      })
        .then((result) => result.json())
        .then((res) => {
          //console.log(res);

          for (const key in res) {
            ////console.log(res[key]);
            
            const row = {}
            row['project'] = res[key][4]
            row['image'] = res[key][5]
            row['tokens'] = this.addDecimal(res[key][2])
            row['wallet'] = this.truncateString(res[key][1], 6, 'start') + "..." + this.truncateString(res[key][1], 6, 'end')
            row['transfered'] = this.formatDate(res[key][0])
            this.transfers.push(row)
          }

          setTimeout(() => { this.userData = res; this.show = true; }, 0);
        });
    },
    numberWithCommas(string) {
      if(string == null)  return;
      let partArray = string.split(".");
      if(partArray.length > 1) {
        let leftPart = partArray[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let rightPart = partArray[1]
        rightPart = rightPart.substring(0,3);
        return leftPart + "." + rightPart;
      }else{
        return string;
      }      
    },
    addDecimal(string) {
      return string;
    },
    formatDate(dateString) {
      let dateFormat = new Date(dateString).toUTCString();
      dateFormat = dateFormat.split(' ').slice(1, 4).join(' ');
      return dateFormat;
      //return dateString;
    },
    truncateString(str, n, pos) {
      if(pos == 'start'){
        if (str.length > n) {
          return str.substring(0, n);
        }
      } else if (pos == 'end'){
        return str.slice(-n);
      }
    }
  }
};
</script>

<style>
  #transfer .v-data-table__mobile-row:first-child{
    justify-content:  center;
  }
  #transfer .v-data-table__mobile-row:first-child img{
    width: 100px !important;
    height: 100px !important;
  }
  #transfer .v-data-table__mobile-row {
    min-height: 30px;
  }
  #transfer .v-data-table__mobile-table-row{
    margin:  10px 0px 20px 0px;
  }
  #transfer .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, #transfer .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th{
    padding-bottom: 20px;
  }
  #transfer .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>.v-data-table__mobile-row:last-child {
    padding-bottom: 20px;
  }
  #transfer table thead tr th {
    padding-top: 20px;
  }
</style>
