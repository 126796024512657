<template>
  <v-sheet
    class=""
  >
    <v-parallax v-if="backgroundImg"
      fill-height
      dark
      :src="backgroundImg"
      height="600"
      v-show="show"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="text-center"
          cols="12"
        >
          <v-slide-y-transition>
            <div v-show="show">
              
              <v-container>
                
                <v-img
                  style="margin: 0 auto ; padding: 0px;"
                  max-height="150"
                  max-width="150"
                  :src="this.tokenIcon"
                  id="logo"
                ></v-img>

                <h1 class="text-h2 font-weight-thin mt-4">
                  Buying crypto is difficult.
                </h1>
                <h2 class="my-4 font-weight-light">
                  That’s why we’ve made it easy.
                  Use traditional payment methods and secure your piece of {{tokenName}} Token today.
                </h2>
              </v-container>
            </div>
          </v-slide-y-transition>
        </v-col>
      </v-row>
    </v-parallax>

    <v-main v-show="show" class="animated my-12" v-bind:class="{ fadeIn: show }">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            lg="6"
            md="7"
          >
            <div class="sticky"><Widget :clientID="clientID" @getProject="getProject" style="max-width: 525px;" class="mb-12" /></div>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="5"
            order-sm="first"
            order-md="first"
          >
            <v-sheet
              min-height="50vh"
              rounded="lg"
              class="ma-4"
            >
              <v-flex>
                <h2>Introducing CURE Pay for {{tokenName}} Token.</h2>
                <p class="pr-10">
                  The easy way to buy {{tokenName}} Token without any knowledge of wallets and exchanges. We handle everything in real-time, so that you don’t have to. Once completed, you will have your own secure dashboard to access your tokens.
                  <br><br>
                  The result is that you have an easy to manage place to store the crypto projects you support. If at any point you want to sell, we provide you with online educational resources to help with that process.
                </p>
                <v-container class="mb-8" style="max-width: 600px;">
                <v-timeline
                  dense
                  clipped
                >
                  <v-timeline-item
                    :color="primaryColor"
                    icon-color="grey lighten-2"
                    large
                    fill-dot
                  >
                    <template v-slot:icon>
                      <span class="white--text font-weight-black headline">1</span>
                    </template>
                    <v-row justify="space-between">
                      <v-col cols="12" class="mt-6">
                        <h3>Choose an Amount </h3>
                        <span class="body-2">Enter the amount of USD
                        that you would like to buy.</span>
                      </v-col>
                    </v-row>
                  </v-timeline-item>

                  <v-timeline-item
                    :color="primaryColor"
                    icon-color="grey lighten-2"
                    large
                    fill-dot
                  >
                    <template v-slot:icon>
                      <span class="white--text font-weight-black headline">2</span>
                    </template>
                    <v-row justify="space-between">
                      <v-col cols="12" class="mt-6">
                        <h3>Enter Your Payment Details</h3>
                        <span class="body-2">You can buy via credit card,
                        debit card, and even Apple Pay.</span>
                      </v-col>
                    </v-row>
                  </v-timeline-item>

                  <v-timeline-item
                    :color="primaryColor"
                    icon-color="grey lighten-2"
                    large
                    fill-dot
                  >
                    <template v-slot:icon>
                      <span class="white--text font-weight-black headline">3</span>
                    </template>
                    <v-row justify="space-between">
                      <v-col cols="12" class="mt-6">
                        <h3>Receive Your Tokens</h3>
                        <span class="body-2">You will get a confirmation
                        email with your token.</span>
                      </v-col>
                    </v-row>
                  </v-timeline-item>

                </v-timeline>
              </v-container>
              <h2>About CURE Pay</h2>
                <p class="pr-10">
                  CURE Pay transacts directly from traditional payment methods to {{tokenName}} Token. These transactions support CURE, CURE is dedicated to using the power of decentralized finance to help rid the planet of dreadful diseases like cancer. Partnering with global research teams and drawing from a wealth of experience in the innovation, technological, and charitable sectors, CURE aims to go one step further than simply becoming the world's most successful charity token. Learn more at <a href='https://www.curetoken.net/' style="color:black !important;" target='_blank'>www.curetoken.net</a>
                </p>
              </v-flex>
            </v-sheet>
          </v-col>

          
        </v-row>
      </v-container>
    </v-main>
  </v-sheet>
</template>


<script>
import axios from 'axios';
import Widget from '../components/Widget.vue';

export default {
  metaInfo() {
    return {
      title: "CURE Pay | " + this.pageTitle,
      meta: [
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content: 'http://cp-product.herokuapp.com/static/screenshots/'+this.pageTitle+'.jpeg'},
        {property: 'og:image:url', content: 'http://cp-product.herokuapp.com/static/screenshots/'+this.pageTitle+'.jpeg'},
        {property: 'og:image:secure_url', content: 'https://cp-product.herokuapp.com/static/screenshots/'+this.pageTitle+'.jpeg'},
        {property: 'og:image:type', content: 'image/jpeg'},
        {property: 'og:image:width', content: '1920'},
        {property: 'og:image:height', content: '1080'},
        {property: 'og:title', content: 'CURE Pay | ' + this.pageTitle},
        {property: 'og:url', content: 'https://www.curepayapp.com/'+this.pageTitle},
        {property: 'og:description', content: 'Introducing CURE Pay. We handle everything in real-time, so that you don’t have to.'},      
      ]
    };
  },
  data() {
    return {
      show: false,
      tokenName: "",
      tokenIcon: "",       
      primaryColor: "",
    };
  },
  props:[
    'clientID',
    'backgroundImg',
    'pageTitle'
  ],
  components: {
    Widget,
  },
  methods: {
    getProject(value) {
      console.log(value);

      this.tokenName = value['name'];
      this.tokenIcon = value['icon'];
      this.primaryColor = value['color'];

      this.show = true;
    }
  },
};
</script>

<style>
  h2{
    font-size: 1.5em;
    line-height: 1.25em;
    margin-bottom: 15px;
  }
  #logo{
    /*border-radius: 50px;
    background-color: white;*/
  }
  .container {
    max-width: 1400px;
  }
  .sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 30px;
  }
</style>