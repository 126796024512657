<template>
  <div style="min-height:200px;" id="summary">
    <v-progress-circular
      :size="40"
      :width="5"
      color="grey lighten-3"
      indeterminate
      class="mb-4 mx-4 animated loader"
      v-bind:class="{ fadeOut: show }"
    ></v-progress-circular>

    <v-data-table v-if="userProjectData.length" class="animated fadeIn" 
      :headers="headers"
      :items="summary"
      :items-per-page="20"
      id="tableSummary"
      @click:row="handleClick"
      mobile-breakpoint="960"
      single-select
    >
      <template v-slot:item.image="{ item }">
        <img :src="item.image" style="margin-top: 5px; width: 50px; height: 50px" class="hand" />
      </template>
      <template v-slot:item.value="{ item }">
        <span v-if="item.value != '-'" class="animated fadeIn" >{{item.value}}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          elevation="0" 
          color="grey"
          class="mr-2" 
          dark
          small
          @click="transfer_modal(item.id, item.project)"
          v-if="parseFloat(item.tokens)"
        >
          TRANSFER TO WALLET
        </v-btn>
        <v-btn
          elevation="0" 
          color="grey"
          dark
          small
          class="mr-2" 
          @click="sellModal(item.id, item.project)"
          v-if="parseFloat(item.tokens)"
        >
          SELL
        </v-btn>
        <v-btn
          elevation="0" 
          :color="primaryColor" 
          dark
          small
          
          @click="buyModal(item.id)"
        >
          BUY
        </v-btn>
        
        <!--<div v-else>REQUIRES PRO FEATURES</div>-->
      </template>
    </v-data-table>

    <v-dialog
      v-model="transferForm"
      persistent
      max-width="500px"
    >
      <v-card v-if="proMode" class="pa-4" id="transferHolder">
        <v-card-title>
          <span class="text-h5">Transfer Your {{curTokenName}} Tokens</span>
        </v-card-title>
        <v-card-text>
          <p class="">You can now transfer your tokens to a software wallet of your choice like MetaMask or Trust Wallet.</p>
          <p class="red--text caption">WARNING: This will transfer all of your current {{curTokenName}} Tokens to this wallet. You may be charged an additional tax depending on the contract tokenomics for transfers. Be certain that this is the accurate address as there is no way to recover your tokens after you click Transfer Tokens.</p>
          <v-form v-model="isFormValid">
            <v-text-field
              label="Wallet Address"
              required
              :color="primaryColor"
              class="mt-4"
              id="walletAddress"
              v-model="transferToWallet"
              :rules="[v => !!v || 'This field is required', v => /0x[a-fA-F0-9]{40}/g.test(v)||'This is now a valid wallet address.']"
            ></v-text-field>
          </v-form>
          <p class="red--text" v-if="transferError">TRANSFER ERROR: {{transferError}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            large
            @click="transferForm = false; isFormValid = true;"
          >
            Cancel
          </v-btn>
          <v-btn
            :color="primaryColor"
            class="white--text px-4"
            :disabled="!isFormValid"
            large
            elevation="0"
            @click="transferTokens(curToken)"
          >
            Transfer Tokens
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-else class="pa-4" id="transferHolder">
        <v-card-title>
          <span class="text-h5">Transfer Your {{curTokenName}} Tokens</span>
        </v-card-title>
        <v-card-text>
          <p class="">You can now transfer your tokens to a software wallet of your choice like MetaMask or Trust Wallet. In order to do this, you must complete an identity verifcation called a KYC.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            large
            @click="transferForm = false; isFormValid = true;"
          >
            Cancel
          </v-btn>
          <v-btn
            :color="primaryColor"
            class="white--text px-4"
            large
            elevation="0"
            @click="requestProFeatures()"
          >
            Perform KYC
          </v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>


    <v-dialog
      v-model="sellForm"
      persistent
      max-width="500px"
    >
      <v-card class="pa-4" id="transferHolder">
        <v-card-title>
          <span class="text-h5">Sell Your {{curTokenName}} Tokens</span>
        </v-card-title>
        <v-card-text>
          <p class="">We're adding in a sell feature for your tokens. This feature will allow you to sell token in exchange for the native currency. Once you have the native currency, you will be able to transfer this to a wallet of your choice or buy a token using this balance. </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="primaryColor"
            class="white--text px-4"
            large
            elevation="0"
            @click="sellForm = false;"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>

  </div>
</template>


<script scoped>

export default {
  props:[
    'email',
    'proMode',
    'primaryColor',
    'user',
  ],
  data () {
    //console.log("SUMMARY : data");
    return {
      embedded: true,
      userProjectData:{},
      loading: true,
      firstLoad: true,
      show: false,
      transferForm: false,
      sellForm: false,
      curToken: '',
      curTokenName: '',
      isFormValid: false,
      transferToWallet: '',
      transferError: '',
      totalPrice: 0,
      headers: [
        {
          text: "",
          align: "left",
          sortable: false,
          value: "image",
        },
        {
          text: 'NAME',
          align: 'start',
          value: 'project',
          sortable: false,
        },
        { text: 'TOKENS', value: 'tokens', sortable: false, },
        { text: 'VALUE', value: 'value', sortable: false, },
        { text: '', value: 'action', align: 'end', sortable: false, },

      ],
      summary: [],
    };
  },
  created() {
    //console.log("SUMMARY : created");
    //console.log(this.user)
    //console.log(this.email)
    this.get_user_projects(this.email);
  },
  components: {
    
  },
  methods: {
    handleClick(value, row) {
      //console.log(value);
      //console.log(row);
      row.select(true);
      this.loadChart(value);
    },
    loadChart(value){
      //console.log(value);
      this.$emit('loadChart', value);
    },
    requestProFeatures(){
      this.transferForm = false;
      console.log("requestProFeaturesEmit")
      this.$emit('requestProFeaturesEmit');
    },
    transfer_modal(token, name) {
      //console.log(token);
      //console.log(name);
      //console.log(this.proMode);

      //if(!this.proMode){
      //  this.transferForm = true;
      //}else{
        this.transferForm = true;
        this.curToken = token;
        this.curTokenName = name;
        //this.$emit('refresh');
      //}
    },
    sellModal(token, name) {
      this.sellForm = true;
      this.curToken = token;
      this.curTokenName = name;
    },
    buyModal(token) {
      this.$emit('buyModal', token);
    },
    async get_user_projects(userEmail) {
      const path = `${this.$domainPath}/get-user-projects`;
      const token = await this.$auth.getTokenSilently();

      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email: userEmail }),
      })
        .then((result) => result.json())
        .then((res) => {
          for (const key in res) {          
            const row = {}
            row['id'] = res[key][2]
            row['project'] = res[key][3]
            row['image'] = res[key][4]
            row['tokens'] = this.numberWithCommas(res[key][1])
            row['value'] = "-"
            row['currentPrice'] = res[key][9]
            row['currentPrice'] = res[key][9]
            row['source'] = res[key][6]
            this.summary.push(row)
            //this.summary[res[key][2]] = row

            //totalPrice += (parseFloat(res[key][10]))
          }

          this.$emit('loadChart', this.summary[Object.keys(this.summary)[0]]);

          setTimeout(() => { this.userProjectData = res; this.show = true; }, 0);

          for (var proj in this.summary) {
            let path = `${this.$domainPath}/get-project-value`;
            fetch(path, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
              },
              body: JSON.stringify({ email: userEmail, project: this.summary[proj].id, proj: proj }),
            })
              .then((result) => result.json())
              .then((res) => {
                this.summary[parseFloat(res['proj'])].value = "$" + this.numberWithCommas(parseFloat(res['currValue']).toFixed(2));
                this.totalPrice = this.totalPrice + (parseFloat(res['currValue']));
                this.$parent.$parent.$parent.$parent.$parent.totalValue = this.numberWithCommas(parseFloat(this.totalPrice).toFixed(2));
              }); 
          }
        });      
    },
    async transferTokens(curToken) {
      this.isFormValid = false;
      
      const path = `${this.$domainPath}/transfer-tokens`;
      const token = await this.$auth.getTokenSilently();
      
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ token: curToken, transferToWallet: this.transferToWallet, email: this.email  }),
      })
        .then((result) => result.json())
        .then((res) => {
          if (res.status == "success") {
            this.transferForm = false;
            this.get_user_projects(this.email);

            //LEFT OFF HERE
            this.$emit('refresh');
          } else {
            this.transferError = res.response;
          }
        });
    },
    numberWithCommas(string) {
      if(string == null)  return;
      let partArray = string.split(".");
      if(partArray.length > 1) {
        let leftPart = partArray[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let rightPart = partArray[1]
        rightPart = rightPart.substring(0,3);
        return leftPart + "." + rightPart;
      }else{
        return string;
      }      
    },
    addDecimal(string) {
      return string;
    },
    formatDate(dateString) {
      let dateFormat = new Date(dateString).toUTCString();
      dateFormat = dateFormat.split(' ').slice(1, 4).join(' ');
      return dateFormat;
      //return dateString;
    },
  }
};
</script>

<style>
  tr.v-data-table__selected {
    background: #ededed !important;
  }
  .v-dialog {
    overflow-y: initial;
  }
  #summary .v-data-table__mobile-row:first-child{
    justify-content:  center;
  }
  #summary .v-data-table__mobile-row:first-child img{
    width: 100px !important;
    height: 100px !important;
  }
  #summary .v-data-table__mobile-row {
    min-height: 30px;
  }
  #summary .v-data-table__mobile-table-row{
    margin:  10px 0px 20px 0px;
  }
  #summary .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, #summary .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th{
    padding-bottom: 20px;
  }
  #summary .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>.v-data-table__mobile-row:last-child {
    padding-bottom: 20px;
  }
  #summary table thead tr th {
    padding-top: 20px;
  }
</style>
