var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "container",
    staticClass: "widget",
    style: {
      height: _vm.containerHeight
    },
    attrs: {
      "id": _vm.mode
    }
  }, [!this.uuid ? _c('v-container', {
    staticStyle: {
      "min-height": "200px"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', [_c('h3', {
    staticClass: "animated fadeIn text-center mt-10"
  }, [_vm._v("No query string provided.")])])], 1)], 1) : _vm._e(), _vm.error ? _c('v-container', {
    staticStyle: {
      "min-height": "200px"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', [_c('h3', {
    staticClass: "animated fadeIn text-center mt-10"
  }, [_vm._v("An error has occurred, please try to refresh or contact support at "), _c('a', {
    attrs: {
      "href": "mailto:support@curepayapp.com"
    }
  }, [_vm._v("support@curepayapp.com")]), _vm._v(".")])])], 1)], 1) : _vm._e(), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.experienceLoaded ? _c('v-stepper', {
    ref: "stepper",
    staticClass: "no-transition",
    attrs: {
      "elevation": 0,
      "transition": "fade-transition"
    },
    model: {
      value: _vm.currentStep,
      callback: function ($$v) {
        _vm.currentStep = $$v;
      },
      expression: "currentStep"
    }
  }, [_c('v-stepper-header', [_vm._l(_vm.steps, function (step, index) {
    return [_c('v-stepper-step', {
      key: ((step.title) + "-step"),
      attrs: {
        "complete": _vm.currentStep > index + 1,
        "step": index + 1,
        "color": _vm.primaryColor
      },
      on: {
        "click": function ($event) {
          return _vm.stepClick(index);
        }
      }
    }, [_vm._v(" " + _vm._s(step.title) + " ")]), index + 1 !== _vm.steps.length ? _c('v-divider', {
      key: step.title
    }) : _vm._e()];
  })], 2), _c('v-stepper-items', [_c('v-stepper-content', {
    staticClass: "animated fadeIn",
    attrs: {
      "step": "1"
    }
  }, [_vm.currentStep == 1 ? _c('Calculate', {
    ref: "calculate",
    attrs: {
      "minbuy": _vm.minbuy,
      "maxbuy": _vm.maxbuy
    },
    on: {
      "purchase": function ($event) {
        return _vm.proceedToCheckout($event);
      }
    }
  }) : _vm._e()], 1), _c('v-stepper-content', {
    staticClass: "animated fadeIn",
    attrs: {
      "step": "2"
    }
  }, [_vm.currentStep == 2 ? _c('Checkout', {
    ref: "checkout",
    attrs: {
      "embedded": _vm.embedded
    },
    on: {
      "process": function ($event) {
        return _vm.proceedToProcess($event);
      }
    }
  }) : _vm._e()], 1), _c('v-stepper-content', {
    staticClass: "animated fadeIn",
    attrs: {
      "step": "3"
    }
  }, [_vm.currentStep == 3 ? _c('Process', {
    ref: "process"
  }) : _vm._e()], 1), _c('v-stepper-content', {
    staticClass: "animated fadeIn",
    attrs: {
      "step": "4"
    }
  }, [_vm.currentStep == 4 ? _c('Complete', {
    ref: "complete",
    attrs: {
      "embedded": _vm.embedded
    }
  }) : _vm._e()], 1)], 1), !_vm.embedded ? _c('div', {
    staticClass: "Footer-PoweredBy mt-0"
  }, [_vm.exp == 'alpha' ? _c('span', {
    staticClass: "Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400"
  }, [_vm._v("Powered by "), _c('svg', {
    staticClass: "InlineSVG Icon Footer-PoweredBy-Icon Icon--md",
    attrs: {
      "focusable": "false",
      "width": "120",
      "height": "14",
      "role": "img",
      "aria-labelledby": "stripe-title"
    }
  }, [_c('title', {
    attrs: {
      "id": "stripe-title"
    }
  }, [_vm._v("CURE")]), _c('g', {
    attrs: {
      "fill-rule": "evenodd"
    }
  }, [_c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M4,3.87c1.64,0,3.38.54,3.38,1.93V7.09H5V6.68c0-.26-.27-.4-.8-.4-1,0-1.23.63-1.23,1.3a1.35,1.35,0,0,0,1.5,1.5,3.41,3.41,0,0,0,2.13-.75l1,2a4.87,4.87,0,0,1-3.28,1.1C2,11.47,0,10.09,0,7.69A3.85,3.85,0,0,1,4,3.87Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M13.53,6.11h-.89V4h3.81V8.91a.29.29,0,0,0,.31.31h.53V11.3H14.72c-.69,0-1.05-.3-1.05-.89,0-.17,0-.35,0-.35h0a2.33,2.33,0,0,1-2.31,1.41c-1.21,0-2.43-.6-2.43-2.69V6.11H8.05V4h3.81V8.26c0,.49.17.66.53.66.77,0,1.14-.84,1.14-1.76Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M18.12,9.23h.93V6.42a.28.28,0,0,0-.3-.31h-.58V4h2.61c.67,0,1.08.27,1.08,1v.35a1.3,1.3,0,0,1,0,.31h0a2.53,2.53,0,0,1,2.31-1.77l.25,0V6.78a3.12,3.12,0,0,0-.53,0c-1.28,0-1.91.54-1.91,1.82v.67h.94V11.3h-4.8Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M28.67,3.87A3.3,3.3,0,0,1,32.2,7.31a7.15,7.15,0,0,1-.05.8H27.81a1.29,1.29,0,0,0,1.37,1,4.54,4.54,0,0,0,2-.63l1,2a5.39,5.39,0,0,1-3.24,1,3.78,3.78,0,0,1-4.09-3.86A3.64,3.64,0,0,1,28.67,3.87Zm.54,3c0-.49-.12-1-.63-1s-.69.74-.72,1Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M40.54,0h2.4V13.66h-2.4Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M51.1,9.15h.76l2.53-7.92h3.46l2.54,7.92h.77V11.3H56.6V9.15h.79l-.2-.93H55.05l-.2.93h.79V11.3H51.1Zm5.63-2.92-.32-1.38c-.17-.75-.25-1.35-.25-1.35h-.07a10.81,10.81,0,0,1-.26,1.35L55.5,6.23Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M62,3.31h-.89V1.23H64.9v7c0,.72.12.88.64.88h.25v2.3a7.75,7.75,0,0,1-.79,0c-1.41,0-3-.27-3-3.06Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M67.35,12V6.42a.28.28,0,0,0-.3-.31h-.58V4H69c.58,0,1,.17,1,.69a1,1,0,0,1,0,.14h0a2.63,2.63,0,0,1,2.17-1c1.88,0,3.25,1.55,3.25,3.78s-1.5,3.82-3.35,3.82a2.61,2.61,0,0,1-1.76-.6h0a3.75,3.75,0,0,1,0,.55V12h.82v2.08H66.54V12Zm3.94-2.95c.64,0,1.15-.48,1.15-1.36S72,6.3,71.32,6.3s-1.14.45-1.14,1.38C70.18,8.4,70.53,9.08,71.29,9.08Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M76.06,9.23h.81V3.31H76V1.23H79.8v3.1a4.4,4.4,0,0,1,0,.63h0a2.58,2.58,0,0,1,4.68,1.6V9.23h.81V11.3H81.54V7.13c0-.49-.18-.63-.55-.63-.91,0-1.19.85-1.19,1.67V9.23h.81V11.3H76.06Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M89.49,6.91h.42V6.58c0-.33-.39-.46-.79-.46a4.44,4.44,0,0,0-2,.69l-1-2a5.85,5.85,0,0,1,3.31-1c2.11,0,3.36,1.09,3.36,3v2a.29.29,0,0,0,.31.31h.44V11.3H91.2a.92.92,0,0,1-1-.89v0a.8.8,0,0,1,0-.11h0a2.29,2.29,0,0,1-2.06,1.15,2.2,2.2,0,0,1-2.34-2.14C85.78,7.2,88.4,6.91,89.49,6.91Zm-.41,2.62a1,1,0,0,0,.87-1V8.21h-.17c-.88,0-1.19.42-1.19.8A.48.48,0,0,0,89.08,9.53Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M97.79,9.15h.73l2.44-3L98.67,3.39h-.81V1.23h4.51V3.39h-.64l.63.76a4.51,4.51,0,0,1,.35.5h0s.13-.21.35-.5l.61-.76h-.63V1.23h4.23V3.39h-.73l-2.41,2.94,2.35,2.82h.76V11.3h-4.53V9.15h.72l-.7-.84a5.19,5.19,0,0,1-.35-.51h0s-.14.23-.35.51l-.63.84h.67V11.3H97.79Z"
    }
  })])])]) : _c('a', {
    staticClass: "Link Link--primary",
    attrs: {
      "href": "https://www.curepayapp.com",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_c('span', {
    staticClass: "Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400"
  }, [_vm._v("Powered by "), _c('svg', {
    staticClass: "InlineSVG Icon Footer-PoweredBy-Icon Icon--md",
    attrs: {
      "focusable": "false",
      "viewBox": "-3 -4 35 12",
      "height": "20",
      "role": "img",
      "aria-labelledby": "stripe-title"
    }
  }, [_c('title', {
    attrs: {
      "id": "stripe-title"
    }
  }, [_vm._v("CURE")]), _c('g', {
    attrs: {
      "fill-rule": "evenodd"
    }
  }, [_c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M4.26,5.55A1.68,1.68,0,0,1,2.44,3.71,1.49,1.49,0,0,1,4.05,2c.56,0,1,.18,1,.53V3H7.13V1.83C7.13.5,5.39,0,4,0A3.79,3.79,0,0,0,0,3.8,3.83,3.83,0,0,0,4.1,7.55,4.8,4.8,0,0,0,7.24,6.44L6.43,4.75A3.67,3.67,0,0,1,4.26,5.55Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M16,5.32V.17H12.6V1.92h1v1.3c0,1.09-.54,2.2-1.5,2.2-.53,0-.79-.28-.79-1V.17H7.88V1.92h1V4.85c0,2,1.1,2.7,2.37,2.7a2.61,2.61,0,0,0,2.45-1.39h0s0,.12,0,.21v.2c0,.49.33.81,1,.81h2.23V5.63h-.61A.28.28,0,0,1,16,5.32Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M21.16,1.83h0a1.89,1.89,0,0,0,0-.36V1.09c0-.66-.37-.92-1-.92H17.85V1.92h.67a.28.28,0,0,1,.31.31v3.4h-1V7.38h4.38V5.63h-1v-1c0-1.32.65-2.17,2-2.17a2.62,2.62,0,0,1,.49,0V.07a1.53,1.53,0,0,0-.28,0A2.48,2.48,0,0,0,21.16,1.83Z"
    }
  }), _c('path', {
    staticClass: "cls-1",
    attrs: {
      "d": "M28.48,5.55A1.61,1.61,0,0,1,26.8,4.22h4.58s.05-.49.05-.73A3.2,3.2,0,0,0,28.07,0a3.59,3.59,0,0,0-3.75,3.77,3.73,3.73,0,0,0,4,3.78,5,5,0,0,0,3.11-1l-.88-1.68A4,4,0,0,1,28.48,5.55ZM28,1.82a1,1,0,0,1,.91,1.07H26.85A1.19,1.19,0,0,1,28,1.82Z"
    }
  })])])])])]) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }