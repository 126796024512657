import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#cccccc',
        secondary: '#2e2e2e',
        base: '#000000'
      },
      dark: {
        primary: '#bfbfbf',
        secondary: '#2e2e2e',
        base: '#ffffff'
      },
    },
  },
});
