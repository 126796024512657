import Vue from 'vue';
import Router from 'vue-router';
import Main from '../components/Main.vue';
import Admin from '../components/Admin.vue';
import Dashboard from '../components/Dashboard.vue';
import Widget from '../components/Widget.vue';
import VueMeta from 'vue-meta'
import Project from '../pages/Project.vue';

import { authGuard } from '../auth';

Vue.use(Router);
Vue.use(VueMeta);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Main',
      component: Main,
    },
    {
      path: '/kyc',
      name: 'KYC',
    },
    {
      path: '/widget',
      name: 'Widget',
      component: Widget,
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      beforeEnter: authGuard,
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      beforeEnter: authGuard,
    },


    
    {
      path: '/pxlc',
      name: 'pxlc',
      component: Project,
      props: { clientID: "665091a9-b990-41e9-a5ec-55c7a6ae301e", backgroundImg: '/img/bg.jpg', pageTitle: 'PXLC' }
    },
    {
      path: '/chain',
      name: 'chain',
      component: Project,
      props: { clientID: "04df1a37-b0e2-4874-8fb6-d2f94e0d3e24", backgroundImg: '/img/bg.jpg', pageTitle: 'CHAIN' }
    },
    {
      path: '/poodl',
      name: 'poodl',
      component: Project,
      props: { clientID: "1be64971-5b8a-491f-8dff-c638cc864e73", backgroundImg: '/img/bg.jpg', pageTitle: 'POODL' }
    },
    {
      path: '/hiro',
      name: 'hiro',
      component: Project,
      props: { clientID: "20b04082-7523-455c-b0b2-62cf3bf37434", backgroundImg: '/img/hiro.jpg', pageTitle: 'HIRO' }
    },
    {
      path: '/seek',
      name: 'seek',
      component: Project,
      props: { clientID: "45c1f648-7662-4097-a493-12afd9bb1110", backgroundImg: '/img/bg.jpg', pageTitle: 'SEEK' }
    },
    {
      path: '/agen',
      name: 'agen',
      component: Project,
      props: { clientID: "557449dd-0b2b-42ce-865f-dd0ecb3273b9", backgroundImg: '/img/bg.jpg', pageTitle: 'AGEN' }
    },
    {
      path: '/cure',
      name: 'cure',
      component: Project,
      props: { clientID: "6eae00be-b74e-4841-99fb-13a0d70ed737", backgroundImg: '/img/bg.jpg', pageTitle: 'CURE' }
    },
    {
      path: '/sod',
      name: 'sod',
      component: Project,
      props: { clientID: "b8d46027-6665-4977-a6cb-20a2fe1da829", backgroundImg: '/img/bg.jpg', pageTitle: 'SOD' }
    },
    {
      path: '/elephant',
      name: 'elephant',
      component: Project,
      props: { clientID: "54a00af8-776e-4f52-801c-c6dd75ffe62f", backgroundImg: '/img/bg.jpg', pageTitle: 'ELEPHANT' }
    },
    {
      path: '/lvm',
      name: 'lvm',
      component: Project,
      props: { clientID: "eacfac06-f207-41fd-bfb5-c0b7778bf86c", backgroundImg: '/img/bg.jpg', pageTitle: 'LVM' }
    },
    {
      path: '/dbnb',
      name: 'dbnb',
      component: Project,
      props: { clientID: "a1a10a7c-d49c-448e-ae4b-89fc18e12385", backgroundImg: '/img/dbnb.png', pageTitle: 'DBNB' }
    },
    {
      path: '/glow',
      name: 'glow',
      component: Project,
      props: { clientID: "78add679-c34c-406c-96e4-aab46c8d7634", backgroundImg: '/img/bg.jpg', pageTitle: 'GLOW' }
    },
    {
      path: '/boxa',
      name: 'boxa',
      component: Project,
      props: { clientID: "3fdffc84-dede-4c29-8e81-94caaad0aade", backgroundImg: '/img/bg.jpg', pageTitle: 'BOXA' }
    },
    {
      path: '/prop',
      name: 'prop',
      component: Project,
      props: { clientID: "21c0b7d1-6634-407a-ab98-ff6696560c37", backgroundImg: '/img/bg.jpg', pageTitle: 'PROP' }
    },
    {
      path: '/guard',
      name: 'guard',
      component: Project,
      props: { clientID: "7758cee1-1a45-42b8-b6e8-12cb58b1da5d", backgroundImg: '/img/guard.png', pageTitle: 'GUARD' }
    },
    {
      path: '/knight',
      name: 'knight',
      component: Project,
      props: { clientID: "71d20d64-d182-4353-9dca-0d8dfe5dd7ea", backgroundImg: '/img/knight.png', pageTitle: 'KNIGHT' }
    },
    {
      path: '/avoteo',
      name: 'avoteo',
      component: Project,
      props: { clientID: "b4a2c336-db98-4e97-a215-c4e648261a28", backgroundImg: '/img/avoteo.png', pageTitle: 'AVOTEO' }
    },
    {
      path: '/tkr',
      name: 'tkr',
      component: Project,
      props: { clientID: "0cea9b54-9dec-4872-8c62-d4f709e5d4f0", backgroundImg: '/img/bg.jpg', pageTitle: 'TKR' }
    },
    {
      path: '/gshiba',
      name: 'gshiba',
      component: Project,
      props: { clientID: "4edd4d66-b63b-44af-ba2a-a1fa677a808b", backgroundImg: '/img/bg.jpg', pageTitle: 'GSHIBA' }
    },
    {
      path: '/soku',
      name: 'soku',
      component: Project,
      props: { clientID: "3b420380-6301-4dcd-acbf-f321229774c0", backgroundImg: '/img/bg.jpg', pageTitle: 'SOKU' }
    },
    {
      path: '/grimace',
      name: 'grimace',
      component: Project,
      props: { clientID: "9811816d-13fc-48a9-b2f4-6a3fcb15d728", backgroundImg: '/img/bg.jpg', pageTitle: 'GRIMACE' }
    },
    {
      path: '/vxl',
      name: 'vxl',
      component: Project,
      props: { clientID: "1b6d9ba8-c407-4c83-9a76-b08683976f03", backgroundImg: '/img/bg.jpg', pageTitle: 'VXL' }
    },
    {
      path: '/jdb',
      name: 'jdb',
      component: Project,
      props: { clientID: "e5c97a59-5695-4d2f-b78e-0b0a9958541b", backgroundImg: '/img/bg.jpg', pageTitle: 'JDB' }
    },
  ],
});
