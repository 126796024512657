var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return this.$parent.$parent.$parent.currentStep == 1 && this.show ? _c('v-card', {
    staticClass: "text-center",
    attrs: {
      "id": "calculate"
    }
  }, [_c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-text-field', {
    class: "pb-4 custom-placeholder-color ma-4",
    staticStyle: {
      "width": "fit-content",
      "block-size": "fit-content",
      "margin": "0 auto",
      "font-size": "100px",
      "font-weight": "bold",
      "text-align": "center"
    },
    attrs: {
      "prepend-inner-icon": "mdi-currency-usd",
      "height": "100",
      "color": _vm.primaryColor,
      "id": "purchaseInput",
      "placeholder": _vm.minbuy,
      "clearable": "",
      "type": "number",
      "hide-spin-buttons": "",
      "required": "",
      "oninput": _vm.purchaseOnInput,
      "rules": _vm.purchaseRules
    },
    on: {
      "keypress": function ($event) {
        return _vm.isNumber($event);
      }
    },
    model: {
      value: _vm.purchaseAmount,
      callback: function ($$v) {
        _vm.purchaseAmount = $$v;
      },
      expression: "purchaseAmount"
    }
  }), _c('div', {
    staticStyle: {
      "display": "inline-block"
    }
  }, [_c('strong', {
    staticStyle: {
      "font-size": "18px"
    },
    attrs: {
      "id": "totalToken"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "inline-block",
      "text-align": "right"
    }
  }, [_vm._v("~" + _vm._s(_vm.numberWithCommas(_vm.tokenAmount.toFixed(2))) + " " + _vm._s(this.tokenName) + " "), _c('br'), _c('p', {
    staticStyle: {
      "font-size": ".6em",
      "float": "right"
    }
  }, [_vm._v("(Actual token amount will vary.)")])]), _c('v-img', {
    staticStyle: {
      "float": "right",
      "margin-top": "-10px",
      "margin-left": "10px"
    },
    attrs: {
      "max-height": "65",
      "max-width": "65",
      "src": this.tokenIcon,
      "eager": ""
    }
  })], 1)]), _c('p', {
    staticClass: "py-5 my-4",
    attrs: {
      "id": "summary"
    }
  }, [_c('table', {
    staticStyle: {
      "width": "90%",
      "margin": "auto"
    }
  }, [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_vm._v("Approx Token Price")]), _c('td', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm._v("$" + _vm._s(_vm.currentPrice))])]), _c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_vm._v("Processing Fees")]), _c('td', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm._v(_vm._s(_vm.processingFees) + "%")])]), _c('tr', [_c('td', {
    staticClass: "font-weight-bold",
    staticStyle: {
      "text-align": "left"
    }
  }, [_vm._v("Total Value")]), _c('td', {
    staticClass: "font-weight-bold",
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm._v(" $" + _vm._s(_vm.numberWithCommas((_vm.tokenAmount * _vm.currentPrice).toFixed(2))) + " ")])])])]), _c('div', {
    staticClass: "mt-4 mx-4"
  }, [_c('v-checkbox', {
    attrs: {
      "rules": [function (v) {
        return !!v || 'You must agree in order to continue.';
      }],
      "label": "I agree to the terms and conditions",
      "required": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = true;
      }
    },
    model: {
      value: _vm.checked,
      callback: function ($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "500px"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    staticStyle: {
      "padding": "10px"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 mb-0",
    staticStyle: {
      "word-break": "break-word"
    }
  }, [_vm._v(" Agree to the Terms and Conditions ")]), _c('v-card-text', {
    staticStyle: {
      "height": "300px"
    }
  }, [_c('p', {
    staticClass: "text-left",
    staticStyle: {
      "margin-bottom": "10px",
      "font-size": "12px",
      "line-height": "1.5em"
    }
  }, [_vm._v(" " + _vm._s(_vm.terms) + " ")]), _c('p', {
    staticClass: "text-left",
    staticStyle: {
      "margin-bottom": "10px",
      "font-size": "12px",
      "line-height": "1.5em"
    }
  }, [_vm._v(" Purchasing and selling tokens is inherently risky and holders must bear their risk of loss. Neither the authors of this website nor any participants in the " + _vm._s(_vm.tokenName) + " project accept any liability for losses or taxes that holders, purchasers or sellers of " + _vm._s(_vm.tokenName) + " may incur. The value of " + _vm._s(_vm.tokenName) + " may decrease and may be highly volatile. ")]), _c('p', {
    staticClass: "text-left",
    staticStyle: {
      "margin-bottom": "10px",
      "font-size": "12px",
      "line-height": "1.5em"
    }
  }, [_vm._v("This website is not directed towards any person located in a jurisdiction where purchasing, selling or holding " + _vm._s(_vm.tokenName) + " is prohibited or regulated. Consult your local laws before transacting in any cryptocurrency.")])]), _c('v-card-actions', {
    staticClass: "mt-4"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false, _vm.checked = false;
      }
    }
  }, [_vm._v(" Disagree ")]), _c('v-btn', {
    attrs: {
      "color": _vm.primaryColor,
      "dark": "",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false, _vm.checked = true;
      }
    }
  }, [_vm._v(" Agree ")])], 1)], 1)], 1), _c('v-btn', {
    staticClass: "white--text",
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      "color": _vm.primaryColor,
      "x-large": "",
      "block": "",
      "disabled": _vm.buttonMaxBuy()
    },
    on: {
      "click": _vm.purchase
    }
  }, [_vm._v(" CHECKOUT NOW ")]), _c('span', {
    attrs: {
      "id": "hdnBtn"
    }
  }, [_c('v-btn', {
    staticClass: "white--text md-elevation-0",
    staticStyle: {
      "box-shadow": "none",
      "width": "5px"
    },
    attrs: {
      "small": "",
      "width": "5",
      "height": "5"
    },
    on: {
      "click": _vm.purchase
    }
  })], 1), _c('div', {
    staticClass: "pb-6"
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }