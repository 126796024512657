<template>
  <div style="min-height:200px;" id="purchase">
    
    <v-progress-circular
      :size="40"
      :width="5"
      color="grey lighten-3"
      indeterminate
      class="mb-4 mx-4 animated loader"
      v-bind:class="{ fadeOut: show }"
    ></v-progress-circular>

    <v-data-table v-if="userData.length" class="animated fadeIn" 
      :headers="headers"
      :items="transactions"
      :items-per-page="5"
      id="tableSummary"
      mobile-breakpoint="960"
    >
      <template v-slot:item.image="{ item }">
        <img :src="item.image" style="margin-top: 5px; width: 50px; height: 50px" />
      </template>
    </v-data-table>
    
  </div>
</template>


<script scoped>

export default {
  props:[
    'email',
    'primaryColor'
  ],
  data () {
    //console.log("PURCHASE : data");
    return {
      userData:{},
      loading: true,
      firstLoad: true,
      show: false,
      headers: [
        {
          text: "",
          align: "left",
          sortable: false,
          value: "image",
          width: "1"
        },
        {
          text: 'NAME',
          align: 'start',
          value: 'project',
          width: "100",
          sortable: false
        },
        { text: 'AMOUNT', value: 'price', sortable: false, },
        { text: 'TOKENS', value: 'tokens', sortable: false, },
        { text: 'PURCHASED', value: 'purchased', sortable: false, },

      ],
      transactions: [],
    };
  },
  created() {
    //console.log("PURCHASE : created");
    this.get_user_transactions(this.email);
  },
  methods: {
    async get_user_transactions(userEmail) {
      //console.log("get_user_transactions");
      const path = `${this.$domainPath}/get-user-transactions`;
      const token = await this.$auth.getTokenSilently();
      fetch(path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ email: userEmail }),
      })
        .then((result) => result.json())
        .then((res) => {
          ////console.log(res);

          for (const key in res) {
            ////console.log(res[key]);
            
            const row = {}
            row['project'] = res[key][6]
            row['image'] = res[key][7]
            row['price'] = "$" + this.addDecimal(res[key][3])
            row['tokens'] = this.numberWithCommas(res[key][4])
            row['purchased'] = this.formatDate(res[key][0])
            this.transactions.push(row)
          }

          ////console.log(this.transactions);

          setTimeout(() => { this.userData = res; this.show = true; }, 0);
        });
    },
    numberWithCommas(string) {
      if(string == null)  return;
      let partArray = string.split(".");
      if(partArray.length > 1) {
        let leftPart = partArray[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let rightPart = partArray[1]
        rightPart = rightPart.substring(0,3);
        return leftPart + "." + rightPart;
      }else{
        return string;
      }      
    },
    addDecimal(string) {
      return string;
    },
    formatDate(dateString) {
      let dateFormat = new Date(dateString).toUTCString();
      dateFormat = dateFormat.split(' ').slice(1, 4).join(' ');
      return dateFormat;
      //return dateString;
    },
  }
};
</script>

<style>
  #purchase .v-data-table__mobile-row:first-child{
    justify-content:  center;
  }
  #purchase .v-data-table__mobile-row:first-child img{
    width: 100px !important;
    height: 100px !important;
  }
  #purchase .v-data-table__mobile-row {
    min-height: 30px;
  }
  #purchase .v-data-table__mobile-table-row{
    margin:  10px 0px 20px 0px;
  }
  #purchase .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, #purchase .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th{
    padding-bottom: 20px;
  }
  #purchase .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr>.v-data-table__mobile-row:last-child {
    padding-bottom: 20px;
  }
  #purchase table thead tr th {
    padding-top: 20px;
  }
</style>
