var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "min-height": "200px"
    },
    attrs: {
      "id": "summary"
    }
  }, [_c('v-progress-circular', {
    staticClass: "mb-4 mx-4 animated loader",
    class: {
      fadeOut: _vm.show
    },
    attrs: {
      "size": 40,
      "width": 5,
      "color": "grey lighten-3",
      "indeterminate": ""
    }
  }), _vm.userProjectData.length ? _c('v-data-table', {
    staticClass: "animated fadeIn",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.summary,
      "items-per-page": 20,
      "id": "tableSummary",
      "mobile-breakpoint": "960",
      "single-select": ""
    },
    on: {
      "click:row": _vm.handleClick
    },
    scopedSlots: _vm._u([{
      key: "item.image",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('img', {
          staticClass: "hand",
          staticStyle: {
            "margin-top": "5px",
            "width": "50px",
            "height": "50px"
          },
          attrs: {
            "src": item.image
          }
        })];
      }
    }, {
      key: "item.value",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.value != '-' ? _c('span', {
          staticClass: "animated fadeIn"
        }, [_vm._v(_vm._s(item.value))]) : _vm._e()];
      }
    }, {
      key: "item.action",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [parseFloat(item.tokens) ? _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "elevation": "0",
            "color": "grey",
            "dark": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.transfer_modal(item.id, item.project);
            }
          }
        }, [_vm._v(" TRANSFER TO WALLET ")]) : _vm._e(), parseFloat(item.tokens) ? _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "elevation": "0",
            "color": "grey",
            "dark": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.sellModal(item.id, item.project);
            }
          }
        }, [_vm._v(" SELL ")]) : _vm._e(), _c('v-btn', {
          attrs: {
            "elevation": "0",
            "color": _vm.primaryColor,
            "dark": "",
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.buyModal(item.id);
            }
          }
        }, [_vm._v(" BUY ")])];
      }
    }], null, false, 256727742)
  }) : _vm._e(), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "500px"
    },
    model: {
      value: _vm.transferForm,
      callback: function ($$v) {
        _vm.transferForm = $$v;
      },
      expression: "transferForm"
    }
  }, [_vm.proMode ? _c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "id": "transferHolder"
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "text-h5"
  }, [_vm._v("Transfer Your " + _vm._s(_vm.curTokenName) + " Tokens")])]), _c('v-card-text', [_c('p', {}, [_vm._v("You can now transfer your tokens to a software wallet of your choice like MetaMask or Trust Wallet.")]), _c('p', {
    staticClass: "red--text caption"
  }, [_vm._v("WARNING: This will transfer all of your current " + _vm._s(_vm.curTokenName) + " Tokens to this wallet. You may be charged an additional tax depending on the contract tokenomics for transfers. Be certain that this is the accurate address as there is no way to recover your tokens after you click Transfer Tokens.")]), _c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function ($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "Wallet Address",
      "required": "",
      "color": _vm.primaryColor,
      "id": "walletAddress",
      "rules": [function (v) {
        return !!v || 'This field is required';
      }, function (v) {
        return /0x[a-fA-F0-9]{40}/g.test(v) || 'This is now a valid wallet address.';
      }]
    },
    model: {
      value: _vm.transferToWallet,
      callback: function ($$v) {
        _vm.transferToWallet = $$v;
      },
      expression: "transferToWallet"
    }
  })], 1), _vm.transferError ? _c('p', {
    staticClass: "red--text"
  }, [_vm._v("TRANSFER ERROR: " + _vm._s(_vm.transferError))]) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey",
      "text": "",
      "large": ""
    },
    on: {
      "click": function ($event) {
        _vm.transferForm = false;
        _vm.isFormValid = true;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "white--text px-4",
    attrs: {
      "color": _vm.primaryColor,
      "disabled": !_vm.isFormValid,
      "large": "",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.transferTokens(_vm.curToken);
      }
    }
  }, [_vm._v(" Transfer Tokens ")])], 1)], 1) : _c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "id": "transferHolder"
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "text-h5"
  }, [_vm._v("Transfer Your " + _vm._s(_vm.curTokenName) + " Tokens")])]), _c('v-card-text', [_c('p', {}, [_vm._v("You can now transfer your tokens to a software wallet of your choice like MetaMask or Trust Wallet. In order to do this, you must complete an identity verifcation called a KYC.")])]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey",
      "text": "",
      "large": ""
    },
    on: {
      "click": function ($event) {
        _vm.transferForm = false;
        _vm.isFormValid = true;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "white--text px-4",
    attrs: {
      "color": _vm.primaryColor,
      "large": "",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.requestProFeatures();
      }
    }
  }, [_vm._v(" Perform KYC ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "500px"
    },
    model: {
      value: _vm.sellForm,
      callback: function ($$v) {
        _vm.sellForm = $$v;
      },
      expression: "sellForm"
    }
  }, [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "id": "transferHolder"
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "text-h5"
  }, [_vm._v("Sell Your " + _vm._s(_vm.curTokenName) + " Tokens")])]), _c('v-card-text', [_c('p', {}, [_vm._v("We're adding in a sell feature for your tokens. This feature will allow you to sell token in exchange for the native currency. Once you have the native currency, you will be able to transfer this to a wallet of your choice or buy a token using this balance. ")])]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "white--text px-4",
    attrs: {
      "color": _vm.primaryColor,
      "large": "",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.sellForm = false;
      }
    }
  }, [_vm._v(" Ok ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }